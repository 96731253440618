import { useMemo, useState } from 'react'
import FormComponent from 'components/Form/Form'
import toast from 'components/toast'
import { objectToFormData } from 'helpers'
import { useApi } from 'hooks/useApi'

const filesTypes = {
	PDF: "application/pdf",
	ZIP: "application/zip",
	XZIP: "application/x-zip-compressed",
	CSV: "text/csv",
	PNG: "image/png",
	JPG: "image/jpeg",
	JPEG: "image/jpeg",
	GIF: "image/gif",
	SVG: "image/svg+xml",
	TXT: "text/plain",
	HTML: "text/html",
	XML: "application/xml",
	JSON: "application/json"
}

const FilesToSend = ({ onSent = async () => { } }) => {
	const [loading, setLoading] = useState(false)
	const { fetchApi, getNewReference } = useApi()

	const inputs = useMemo(() => [
		{
			type: 'file',
			name: 'capRenovCsv',
			preview: true,
			label: 'CSV téléchargé de CapRenov',
			info: 'Téléchargez le CSV de l\'audit via le logiciel CapRenov et ajoutez-le ici.',
		},
		{
			type: 'file',
			name: 'BAO',
			preview: true,
			label: 'CSV téléchargé de BAO',
			info: 'Téléchargez le CSV de l\'audit via le logiciel BAO et ajoutez-le ici.',
		},
		{
			type: 'file',
			name: 'rapportBAO',
			preview: true,
			label: 'PDF téléchargé de BAO',
			info: 'Téléchargez le PDF de l\'audit via le logiciel BAO et ajoutez-le ici.',
		},
		{
			type: 'file',
			name: 'signature',
			preview: true,
			label: 'Signature du client',
			info: 'Si pas présent dans IAuditor ou vous n\'avez pas le dossier IAuditor',
		},
		{
			type: 'file',
			name: 'iAuditor',
			preview: true,
			label: 'ZIP téléchargé de IAuditor',
			info: 'Téléchargez le .zip de l\'audit via le logiciel IAuditor et ajoutez-le ici.',
		},
		{
			type: 'file',
			name: 'screenEtatInitial',
			preview: true,
			label: 'Screen état initial',
			info: 'Ce document sera ajouté juste après la page du état initial.(PNG/JPEG/JPG/WEBP)',
		},
		{
			type: 'file',
			name: 'screenScenario1',
			preview: true,
			label: 'Screen scénario 1',
			info: 'Ce document sera ajouté juste après la page du scénario 1.(PNG/JPEG/JPG/WEBP)',
		},
		{
			type: 'file',
			name: 'screenScenario2',
			preview: true,
			label: 'Screen scénario 2',
			info: 'Ce document sera ajouté juste après la page du scénario 2.(PNG/JPEG/JPG/WEBP)',
		},
		{
			type: 'file',
			name: 'screenScenario3',
			preview: true,
			label: 'Screen scénario 3',
			info: 'Ce document sera ajouté juste après la page du scénario 3.(PNG/JPEG/JPG/WEBP)',
		},
	], [])

	const sendForm = async (values) => {
		setLoading(true)
		try {
			if (!values.BAO && !values.capRenovCsv) {
				setLoading(false)
				return toast.error("Il faut envoyer un fichier csv, soit capRenov soit BAO. Il semblerait que vous n'en envoyez aucun des deux.")
			}
			if (values.signature && (
				![filesTypes.PNG,
				filesTypes.JPG,
				filesTypes.JPEG].includes(values.signature.type)
			)) {
				setLoading(false)
				return toast.error("La signature doit être de type PNG ou JPEG.")
			}
			if (values.iAuditor &&
				![filesTypes.ZIP, filesTypes.XZIP].includes((values.iAuditor || {}).type)) {
				setLoading(false)
				return toast.error("iAuditor doit être de type ZIP.")
			}
			if (values.BAO && values.capRenovCsv) {
				setLoading(false)
				return toast.error("Il faut choisir entre BAO et CapRenov.")
			}
			if ((values.BAO &&
				values.BAO.type !== filesTypes.CSV)
				|| (values.capRenovCsv &&
					values.capRenovCsv.type !== filesTypes.CSV)) {
				setLoading(false)
				return toast.error("Le fichier " + (values.BAO ? "BAO" : "capRenov") + " doit être de type CSV")
			}
			if (values.BAO && !values.iAuditor) {
				setLoading(false)
				return toast.error("Lorsqu'un fichier BAO est envoyé, il doit être accompagné d'un fichier zip iAuditor. Celui-ci contient les infos du client donc indispensable.")
			}
			// if (values.BAO && !values.rapportBAO) {
			// 	setLoading(false)
			// 	return toast.error("Lorsqu'un fichier BAO est envoyé, il doit être accompagné de son rapport PDF de BAO. Celui-ci sera ajouté à la fin de la synthèse.")
			// }
			const [res, reference] = await Promise.all([
				fetchApi('converter/getPdfFromFiles', {
					body: objectToFormData(values),
					multiform: true,

				}),
				getNewReference()
			])

			setLoading(false)
			toast.success('Le fichier csv a correctement été exploité.')
			return onSent(res, reference)
		} catch (e) {
			setLoading(false)
			console.error(e)
			toast.error(e.message)
		}
	}

	return (
		<FormComponent
			inputs={inputs}
			sendForm={sendForm}
			centered={true}
			// responsive={false}
			segment={false}
			loading={loading}
			messages={{ submit: 'Convertir en rapport d\'audit' }}
		/>
	)
}

export default FilesToSend
