import React from 'react';

const Case = ({
	checked,
	onUpdate = () => { }
}) => {
	return (
		<td onClick={() => onUpdate(!checked)}>
			{checked && 'X'}
		</td>
	);
};

export default Case;