import { isValidDate, timeDateFormat } from "dates"
import { isNumber, isString } from "lodash"
import { useEffect, useState } from "react"
import { noSpaceRegex } from "./helpers"

export const useTableHeader = (rows, setRows) => {
	const filterBySearch = (value, rows) => {
		const newValue = String(value)
			.toLocaleLowerCase()
			.replace(noSpaceRegex, '')

		if (newValue) {
			return rows.filter(row =>
				!!Object.values(row).find((rowValue) => {
					if (isNumber(rowValue) && String(rowValue).toLocaleLowerCase().replace(noSpaceRegex, '').includes(newValue)) { return true }
					else if (isValidDate(rowValue) && String(timeDateFormat(rowValue)).toLocaleLowerCase().replace(noSpaceRegex, '').includes(newValue)) { return true }
					else if (isString(rowValue) && rowValue.toLocaleLowerCase().replace(noSpaceRegex, '').includes(newValue)) { return true }
					return false
				})
			)
		}

		return rows

	}

	const filterByFilter = (valueObj, rows) => {
		const key = Object.keys(valueObj)[0]
		const values = valueObj[key]

		if (!values || values.length === 0) {
			return rows
		}

		const newValues = values.map(
			value => String(value)
				.toLocaleLowerCase()
				.replace(noSpaceRegex, '')
		)

		const filteredRows = rows
			.filter((row, index) => {
				const rowToCompare = row[key]
					.toLocaleLowerCase()
					.replace(noSpaceRegex, '')
				return !!newValues.find(newValue => rowToCompare.includes(
					newValue
						.toLocaleLowerCase()
						.replace(noSpaceRegex, '')
				))
			}
			)
		return filteredRows
	}

	const [filters, setFilters] = useState({
		search: '',
		filter: null
	})
	const [lastFilters, setLastFilters] = useState({
		search: '',
		filter: null
	})

	useEffect(() => {
		if (JSON.stringify(lastFilters) !== JSON.stringify(filters)) {
			let newRows = [...rows]
			if (filters.search) {
				newRows = [...filterBySearch(filters.search, newRows)]
			}
			if (filters.filter) {
				newRows = [...filterByFilter(filters.filter, newRows)]
			}
			setRows([...newRows])
			setLastFilters(filters)
		}

	}, [filters, rows, setRows, lastFilters])

	const updateFilters = (type, value) => {
		setFilters(filters => ({
			...filters,
			[type]: value
		}))
	}

	return {
		updateFilters
	}
}