import { parseUser } from 'helpers'
import { useEffect, useMemo, useState } from 'react'
import { collaboratorTypes } from 'views/homeCompta/collaborator.constant'
import { useStoreUtils } from './useStoreUtils'
import { useApi } from './useApi'

export const useUsers = (arrayLoading = false) => {
	const { getUsers } = useApi()
	const { setTableLoadingOn, setTableLoadingOff } = useStoreUtils()
	const [users, setUsers] = useState([])
	const AMOs = useMemo(() => users.filter(({ type }) => type === collaboratorTypes.AMO), [users])
	const installateurs = useMemo(() => users.filter(({ type }) => type === collaboratorTypes.INSTALLATEUR), [users])
	const engineers = useMemo(() => users.filter(({ type }) => type === collaboratorTypes.INGENIEUR), [users])

	useEffect(() => {
		if (arrayLoading) {
			setTableLoadingOn()
		}
		getUsers()
			.then(users => users.map(parseUser))
			.then(setUsers)
			.finally(() => arrayLoading && setTableLoadingOff())
	}, [])

	return {
		users,
		AMOs,
		installateurs,
		engineers
	}
}
