export const fakeForm = {
	"beneficiaire": "Casagrande Jean Luc",
	"ville": "Beaupuy",
	"chauffage_principal_consomation_situation_initiale": 97702.98,
	"chauffage_principal_consomation_scenario_1": 29514.37,
	"chauffage_principal_consomation_scenario_2": 15767.6,
	"chauffage_apoint_consomation_situation_initiale": 27723.27,
	"chauffage_apoint_consomation_scenario_1": 0,
	"chauffage_apoint_consomation_scenario_2": 0,
	"ecs_situation_initiale": 9869.98,
	"ecs_scenario_1": 1639.73,
	"ecs_scenario_2": 1639.73,
	"conso_cep_situation_initiale": 548.78,
	"conso_cep_scenario_1": 131.39,
	"conso_cep_scenario_2": 75.77,
	"ges_situation_initiale": 142.96,
	"ges_scenario_1": 4.44,
	"ges_scenario_2": 2.53,
	"conso_auxiliaires_ep_1": 765.01,
	"conso_auxiliaires_ep_2": 559.09,
	"conso_auxiliaires_ep_3": 401.45,
	"conso_eclairage_ep_1": 1134.57,
	"conso_eclairage_ep_2": 1134.57,
	"conso_eclairage_ep_3": 1134.57,
	"reference": "QTDGMRJIDK",
	"total_situation_initiale": 135296,
	"total_scenario_1": 31154,
	"total_scenario_2": 17407,
	"conso_CEF_situation_initiale": " ",
	"conso_CEF_scenario_1": " ",
	"conso_CEF_scenario_2": " ",
	"gain_scenario_1": " ",
	"gain_scenario_2": " ",
	"gain_energetique_scenario_1": 76.06,
	"gain_energetique_scenario_2": 86.19,
	"cep_sup_110_scenario_1": "OUI",
	"cep_sup_110_scenario_2": "NON",
	"prime_bar_precaire_scenario_1": " ",
	"prime_bar_precaire_scenario_2": " ",
	"prime_bar_classique_scenario_1": " ",
	"prime_bar_classique_scenario_2": " ",
	"prime_kwh_precaire_scenario_1": " ",
	"prime_kwh_precaire_scenario_2": " ",
	"prime_kwh_classique_scenario_1": " ",
	"prime_kwh_classique_scenario_2": " ",
	"prime_cee_scenario_1": " ",
	"prime_cee_scenario_2": " ",
	"today_date": "29/1/2023",
	"synthese_energetique": "undefined m2 à isoler",
	"date_visite": "1111-11-11",
	"auditeur": "Tom Ktorza",
	"date_rapport": "0001-11-11",
	"situationInitialeEnergie": "Gaz",
	"cases": [
		0,
		0,
		0,
		0,
		0,
		1,
		0,
		3,
		2,
		0,
		2,
		3,
		1,
		0,
		3,
		3
	]
}