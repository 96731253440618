import React, { useMemo, useState } from 'react';
import Cases from './Cases';
// import { fakeRes } from './fakeRes';
import { isEqual } from 'lodash';
import { uniqueArrOfObjects } from 'helpers';
import AirEauValue from './AirEauValue';

const CasesSas = ({
	res,
	onFileDownloaded,
}) => {
	const [airEauValue, setAirEauValue] = useState(false)
	// const res = useMemo(() => fakeRes, [])
	const isBao = useMemo(() => !!res.isBao, [res])
	const populateCases = ({ pacs, ecss }) => {
		// pacs: [
		// 	{
		// 		type: 'POMPE A CHALEUR AIR/EAU',
		// 		identique: 2,
		// 		puissance: 16,
		// 		cop: 4
		// 	}
		// ]
		const airEau = pacs.filter(({ type }) => (type|| '').includes('AIR/EAU'))
			.reduce((prev = [], actual) => {
				const sameOneIndex = prev.findIndex(({ puissance, cop }) => actual.puissance === puissance && actual.cop === cop)
				if (sameOneIndex !== -1) {
					prev[sameOneIndex].identique += actual.identique
					return prev
				}
				return [...prev, actual]
			}, [])
		const airAir = pacs.filter(({ type }) => (type|| '').includes('AIR/AIR'))
			.reduce((prev = [], actual) => {
				const sameOneIndex = prev.findIndex(({ puissance, cop }) => actual.puissance === puissance && actual.cop === cop)
				if (sameOneIndex !== -1) {
					prev[sameOneIndex].identique += actual.identique
					return prev
				}
				return [...prev, actual]
			}, [])
			.map(({
				identique,
				puissance,
				cop,
			}) => ({
				title: 'PAC Air/Air',
				description: "Installation d'une pompe à chaleur Air/Air",
				price: '',
				quantity: identique,
				infos: `Puissance ≥ ${puissance}kW - COP ≥ ${cop}`,
				COP: cop,
				PUISSANCE: puissance
			}))

		const ecssThermo = ecss.filter(({ title }) => title === 'thermodynamique')
			.reduce((prev = [], actual) => {
				const sameOneIndex = prev.findIndex(({ cop }) => actual.cop === cop)
				if (sameOneIndex !== -1) {
					prev[sameOneIndex].nombre += actual.nombre
					return prev
				}
				return [...prev, actual]
			}, []).map(({
				nombre,
				cop,
			}) => ({
				title: 'Chauffe-eau ThermoDynamique (7/55°C)',
				description: "Installation d'un chauffe-eau thermodynamique",
				price: '€5 000',
				quantity: nombre,
				infos: `COP ≥ ${cop}`,
				COP: cop,
				VOLUME: 200,
			}))
		const ecssSolaire = ecss.filter(({ title }) => title === 'solaire')
			.reduce((prev = [], actual) => {
				const sameOneIndex = prev.findIndex(({ cop }) => actual.cop === cop)
				if (sameOneIndex !== -1) {
					prev[sameOneIndex].nombre += actual.nombre
					return prev
				}
				return [...prev, actual]
			}, []).map(({
				nombre,
				cop,
			}) => ({
				title: '"Chauffe-eau Solaire"',
				description: "Installation d'un chauffe-eau solaire",
				price: '€6 500',
				quantity: nombre,
				infos: `COP ≥ ${cop}`,
				COP: cop
			}))
		const extraTravaux = [
			...airAir,
			...ecssThermo,
			...ecssSolaire,
		]

		const airEauTravaux = [...airEau]

		return {
			extraTravaux,
			airEauTravaux,
		}
	}

	const createExtraCases = ({ sc1, sc2, sc3 }, airEauValue) => {
		const sc1Extra = populateCases(sc1)
		const sc2Extra = populateCases(sc2)
		const sc3Extra = populateCases(sc3)
		const getScenarios = (line, type = 'extraTravaux') => {
			const scenarios = [
				!!sc1Extra[type].find(elem => isEqual(elem, line)) ? 1 : null,
				!!sc2Extra[type].find(elem => isEqual(elem, line)) ? 2 : null,
				!!sc3Extra[type].find(elem => isEqual(elem, line)) ? 3 : null
			].filter(e => !!e)
			return (scenarios.length === 3) ? [4] : scenarios
		}
		return [...uniqueArrOfObjects([
			...sc1Extra.extraTravaux,
			...sc2Extra.extraTravaux,
			...sc3Extra.extraTravaux,
		]).map(line => ({
			...line,
			scenarios: getScenarios(line)
		})),
		...uniqueArrOfObjects([
			...sc1Extra.airEauTravaux,
			...sc2Extra.airEauTravaux,
			...sc3Extra.airEauTravaux,
		]).map(line => ({
			...line,
			scenarios: getScenarios(line, 'airEauTravaux')
		})).map(({ identique, puissance, cop, scenarios }) => ({
			title: `PAC Air/Eau ${airEauValue}`,
			description: "Installation d'une pompe à chaleur Air/Eau",
			price: '€17 000',
			quantity: identique,
			infos: `Puissance ≥ ${puissance}kW - COP ≥ ${cop} - Etas >= 126%`,
			PUISSANCE: puissance,
			COP: cop,
			ETAS: 126,
			scenarios
		}))]

	}

	const getExtraOtherThanPAC = ({ jsonSynthese: {
		isolation_mur_exterieur,
		isolation_de_la_toiture,
		isolation_sous_sol
	} }) => [
			// { title: "Isolation du circuit de distribution", M2_A_ISOLER: plafond_m2 || 0 },
			{ title: "Isolation des combles perdus", M2_A_ISOLER: isolation_de_la_toiture || 0 },
			{ title: "Isolation des rampants", M2_A_ISOLER: isolation_de_la_toiture || 0 },
			{ title: "Isolation du plancher bas sur espace non chauffé", M2_A_ISOLER: isolation_sous_sol || 0 },
			{ title: "Isolation des murs par l'intérieur", M2_A_ISOLER: isolation_mur_exterieur || 0 },
			{ title: "Isolation des murs par l'extérieur", M2_A_ISOLER: isolation_mur_exterieur || 0 },
		]

	const resToSend = useMemo(() => {
		if (isBao) {
			const extraCases = createExtraCases(res.jsonSynthese, airEauValue)
			const travaux = [...extraCases.map(({ title }) => title), ...res.travaux
				.filter(t => !!!extraCases.find(({title}) => title === t))
				// !(t.includes('Air/Air') ||
				// 	t.includes('Air/Eau') ||
				// 	t.includes('Chauffe-eau')))
				]

			console.log('extraCases', extraCases)
			return {
				...res,
				travaux,
				extraCases: [...extraCases, ...getExtraOtherThanPAC(res)]
			}
		}
		return { ...res, extraCases: [] }
	}, [res, isBao, airEauValue])

	if (isBao && !airEauValue) {
		return <AirEauValue setAirEauValue={setAirEauValue} />
	}
	return <Cases
		res={resToSend}
		onFileDownloaded={onFileDownloaded}
	/>
};

export default CasesSas;