import classes from 'styles/classes.module.scss'
import MyTable from 'components/MyTable/MyTable';
import React, { useMemo, useState } from 'react';
import Button from 'components/Button';
import moment from 'moment';
import PeriodSelection from './PeriodSelection';
import { isDateBetween } from 'dates'
import { useStoreUtils } from 'hooks/useStoreUtils';
import { columnTypes } from 'components/MyTable/MyTable.constant';
import { getUserSyntheseName } from 'helpers';

const SynthesesTable = ({ syntheses: synthesesReceived, title = '' }) => {
	const { state: { user } } = useStoreUtils()
	const isAdmin = useMemo(() => !!user?.admin, [user])
	const [dates, setDates] = useState([new Date('01/01/1000'), new Date('12/30/20000')])

	const syntheses = useMemo(() => {
		const [startDate, endDate] = dates

		return synthesesReceived
			.map(synthese => ({
				...synthese,
				displayName: getUserSyntheseName({
					firstname: synthese.userSynthese.firstname,
					lastname: synthese.userSynthese.lastname,
					email: synthese.userSynthese.email
				}),
				beneficiaire: synthese.jsonSynthese.beneficiaire,
				full_address: synthese.jsonSynthese.full_address,
				auditeur: synthese.jsonSynthese.auditeur,
			}))
			.filter(synthese => {
				const createdAt = new Date(synthese.createdAt)
				return isDateBetween(
					createdAt,
					startDate,
					endDate
				)
			}).sort((first, second) => {
				const firstDate = first.createdAt ? new Date(first.createdAt) : null
				const secondDate = second.createdAt ? new Date(second.createdAt) : null
				if (firstDate && secondDate) {
					return secondDate.getTime() - firstDate.getTime()
				}
				if (!firstDate && !secondDate) {
					return 0
				}
				return firstDate ? 1 : -1
			})
	}, [synthesesReceived, dates])

	const columns = useMemo(() => [
		{ field: 'id', title: 'ID', render: ({ id }) => /*<Button link={`/synthese/${id}`}>{*/id/*}</Button>*/ },
		{
			field: 'displayName', title: 'Créateur', type: columnTypes.TEXT, render: ({ displayName, userSynthese: { id } }) => {
				return isAdmin ? <>
					{displayName}
					<Button link={`/admin/user/${id}`}>Paramètres de l'utilisateur</Button>
					<Button type='secondary' link={`/syntheses/${id}`}>Synthèses de l'utilisateur</Button>
				</> : displayName
			}
		},
		{ field: 'beneficiaire', title: 'Nom du client', type: columnTypes.TEXT },
		{ field: 'full_address', title: 'Adresse du client', type: columnTypes.TEXT },
		{ field: 'auditeur', title: 'Auditeur', type: columnTypes.TEXT },
		{ field: 'createdAt', title: 'Date de création', type: columnTypes.DATE, render: ({ createdAt }) => moment(createdAt).format('DD/MM/YYYY') },
	], [isAdmin])

	return (
		<div style={{ maxWidth: "100%" }} className={classes.marginAuto}>
			<PeriodSelection updateFunc={({ startDate, endDate }) => setDates([startDate, endDate])} />
			<h2>Total : {syntheses.length}</h2>
			<div style={{ maxWidth: "100%" }} className={classes.marginAuto}>
				<MyTable
					counter={true}
					columns={columns}
					data={syntheses}
					title={title}
					options={{
						paging: true,
						pageSize: 50
					}}
				/>
			</div>
		</div>
	);
};

export default SynthesesTable;