import Button from 'components/Button/Button';
import React from 'react';

const RestitutionChoicePage = ({
	onSent = () => { },
	devAction = () => {}
}) => {
	return (
		<div>
			{process.env.NODE_ENV !== 'production' && <Button type='black' onClick={devAction}>Passer à la dernière étape (DEV)</Button>}
			<Button type='danger' onClick={() => onSent({ restitution: false })}>Pas de modification du Rapport v2</Button>
			<Button type='success' onClick={() => onSent({ restitution: true })}>Modification du rapport v2</Button>
		</div>
	);
};

export default RestitutionChoicePage;