import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Form, Message, Segment, Container, Header, List } from 'semantic-ui-react'
import Button from 'components/Button/Button'
import HorizontalCenteredGrid from '../HorizontalCenteredGrid'
import HorizontalCenteredGridFlexed from '../HorizontalCenteredGridFlexed'
import MessageLinkeable from '../MessageLinkeable'
import OwnIcons from '../OwnIcons'
import ButtonSendForm from './ButtonSendForm'
import Input from './Input'
import Radio from './RadioGroup'
import classes from './Form.module.scss'
import classNames from 'classnames'
import { isUndefined } from './utils'

const FormComponent = ({
    inputs = [],
    sendForm = () => { },
    onUpdate = () => { },
    messages = {},
    segment = false,
    button = {
        align: 'left',
        color: 'blue',
    },
    messageLinkeable = {},
    centered = false,
    flexed = false,
    responsive = true,
    segmentStyle = {},
    loading = false,
    noConfirmationMailLang = {},
    unavailable = null,
}) => {
    const [error, setError] = useState(false)
    const [emptyFields, setEmptyFields] = useState([])
    const requiredLang = 'Ce champ est requis'
    const emptyFieldsLang = 'Champs manquants'
    const submitLang = 'Valider'

    const initValues = (inputs) => {
        const init = {}
        inputs.map((input) => {
            let initValue = input.value == null ? null : input.value
            if (input.value == null && input.required) {
                const { radios = [{}] } = input
                initValue = radios[0].value
            }
            return (init[input.name] = initValue)
        })
        return init
    }
    const [values, setValues] = useState(initValues(inputs))
    useEffect(() => {
        setValues(initValues(inputs))
    }, [inputs])
    const labelExtract = useCallback((label) => (typeof label === 'object' ? label['fr'] : label), ['fr'])
    const checkRequired = useCallback(() => {
        const required = inputs.filter(({ name, required }) => isUndefined(required, values[name]))
        if (required.length > 0) {
            setEmptyFields(
                required.length === 1
                    ? [labelExtract(required[0].label) || required[0].placeholder]
                    : required.map(({ label, placeholder }) => labelExtract(label) || placeholder)
            )
            return false
        }
        return true
    }, [inputs, labelExtract, values])
    const getRadioValue = useCallback((label) =>
        inputs
            .find(({ radios = [] }) => radios.find((radio) => radio.label === label))
            .radios.find((radio) => radio.label === label).value, [inputs])

    useEffect(() => {
        error && checkRequired() && setError(false)
    }, [values])

    const updateValues = useCallback(({ name, value, label, type }) => {
        setValues({
            ...values,
            [name]: type === 'radio' ? getRadioValue(label) : value,
        })
        onUpdate({
            ...values,
            [name]: type === 'radio' ? getRadioValue(label) : value,
        }, [name, value])
    }, [getRadioValue, onUpdate, values])

    const submitForm = useCallback(() => (checkRequired() ? sendForm(values) : setError(true)), [checkRequired, sendForm, values])

    const onSubmit = useCallback((evt) => {
        evt.preventDefault()
        submitForm()
    }, [submitForm])

    const onEnter = useCallback((e) => {
        if (e.key === 'Enter') {
            submitForm()
        }
    }, [submitForm])

    const unavailableComponent = useMemo(() => {
        if (!unavailable) return
        switch (typeof unavailable) {
            case 'string':
                return (
                    <div className={classNames(classes.centeredContainer, classes.limitContainer)}>
                        <Header as="h4" className={classNames(classes.limit)}>
                            {unavailable}
                        </Header>
                    </div>
                )
            case 'object':
                return (
                    <div className={classNames(classes.centeredContainer, classes.limitContainer)}>
                        <div className={classNames(classes.upgradeButtonContainer)}>
                            <Button onClick={unavailable.onClick}>
                                {unavailable.message}
                            </Button>
                            {unavailable.icon ? (
                                <OwnIcons name={unavailable.icon} size={unavailable.iconSize || 75} />
                            ) : null}
                        </div>
                    </div>
                )
            default:
                return null
        }
    }, [unavailable])

    const renderForm = () => (
        <>
            <Form error={error} className={classNames(classes.root, unavailable ? classes.unavailable : '')}>
                <div className={responsive && error ? classes.flex : ''}>
                    <div /*className="field-set"*/>
                        {inputs.map((props, id) =>
                            props.type === 'radio' ? (
                                <Radio
                                    key={id}
                                    onChange={updateValues}
                                    {...props}
                                    formError={error}

                                />
                            ) : (
                                <Input
                                    onKeyPress={onEnter}
                                    key={id}
                                    onChange={updateValues}
                                    responsive={responsive}
                                    formError={error}
                                    {...props}
                                //  toCheck={}
                                />
                            )
                        )}
                    </div>
                    {error ? (
                        <Message
                            error
                            header={emptyFieldsLang}
                            content={
                                <List as="ul" className={classes.formError} bulleted>
                                    {emptyFields.map((a, index) => (
                                        <List.Item as="li" key={index}>
                                            {a}
                                        </List.Item>
                                    ))}
                                </List>
                            }
                        />
                    ) : null}
                </div>

                {messageLinkeable ? (
                    <MessageLinkeable className={classes.messageLinkeable} {...messageLinkeable} />
                ) : null}
                {messageLinkeable.message ? (
                    <MessageLinkeable
                        className={classNames(classes.messageLinkeable, classes.withMessage)}
                        {...noConfirmationMailLang}
                    />
                ) : null}
                {!button ? null : button.align === 'left' ? (
                    <ButtonSendForm
                        button={button}
                        onSubmit={onSubmit}
                        messages={messages}
                        error={error}
                        responsive={responsive}
                        emptyFields={emptyFields}
                        submitLang={messages.submit || submitLang}
                        loading={loading}
                        disabled={loading}
                    />
                ) : (
                    <Button
                        style={{ [button.align]: 0 }}
                        // color={button.color}
                        as="a"
                        onClick={onSubmit}
                        loading={loading}
                        disabled={loading}
                        active
                        className={classes.sendButton}
                    >
                        {messages.submit || submitLang}
                    </Button>
                )}
            </Form>
            {unavailableComponent}
        </>
    )
    return segment ? (
        <Container>
            <Segment {...segmentStyle}>{renderForm()}</Segment>
        </Container>
    ) : centered ? (
        flexed ? (
            <HorizontalCenteredGridFlexed>{renderForm()}</HorizontalCenteredGridFlexed>
        ) : (
            <HorizontalCenteredGrid>{renderForm()}</HorizontalCenteredGrid>
        )
    ) : (
        renderForm()
    )
}

export default FormComponent
