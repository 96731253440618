import FormComponent from 'components/Form/Form';
import toast from 'components/toast';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from 'styles/classes.module.scss'
import { useApi } from 'hooks/useApi';

const AdminCreate = () => {
	const { createUserSynthese } = useApi()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const create = async (infos) => {
        setLoading(true)
        try {
            const user = await createUserSynthese(infos)
            toast.success('Utilisateur créé.')
            setLoading(false)
            navigate('/admin/user/' + user.id)
        } catch (error) {
            setLoading(false)
            toast.error('Erreur durant la création de l\'utilisateur: ' + error.message)
        }
    }

    const inputs = useMemo(() => [
		{
			type: 'text',
			name: 'firstname',
			label: 'Prénom',
		},
		{
			type: 'text',
			name: 'lastname',
			label: 'Nom',
		},
        {
            type: 'text',
            name: 'email',
            label: 'Email',
            required: true,
            silentRequired: true,
        },
		{
			type: 'password',
			name: 'password',
			label: 'Mot de passe',
			required: true,
			silentRequired: true,
		},

    ], [])


    return (
        <div className={classes.marginAuto}>
            <h2>Création de l'utilisateur</h2>
            <FormComponent
                loading={loading}
                inputs={inputs}
                sendForm={create}
                centered={true}
                responsive={false}
                segment={false}
            />
        </div>
    );
};

export default AdminCreate;