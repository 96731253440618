import React from 'react';
import JsonSyntheseInput from './JsonSyntheseInput';
import { numberToString } from 'helpers';

const TablePage2_2 = ({

	updateJsonSynthese = () => { },
	...jsonSynthese
}) => {
	const { conso_cef_situation_initiale,
		conso_cep_situation_initiale,
		conso_cef_scenario_1,
		conso_cep_scenario_1,
		conso_cef_scenario_3,
		conso_cep_scenario_3,
		gain_scenario_1,
		gain_scenario_3,
		// ges_situation_initiale,
		// ges_scenario_1,
		// ges_scenario_3,
		gain_energetique_scenario_1,
		gain_energetique_scenario_3,
		cep_sup_110_scenario_1,
		cep_sup_110_scenario_3,
		conso_cef_scenario_2,
		conso_cep_scenario_2,
		gain_scenario_2,
		// ges_scenario_2,
		gain_energetique_scenario_2,
		cep_sup_110_scenario_2 } = jsonSynthese
	return (
		<div className="ta-center">
			<table className="m2-auto">
				<thead>
					<tr>
						<th className="ta-center">
						</th>
						<th colSpan="2" className="font-medium ta-center">
							Situation initiale
						</th>
						<th colSpan="2" className="font-medium ta-center">
							Scénario 1
						</th>
						<th colSpan="2" className="font-medium ta-center">
							Scénario 2
						</th>
						<th colSpan="2" className="font-medium ta-center">
							Scénario 3
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td className="font-medium align-left">Energie Finale/Primaire</td>
						<td className="font-medium bg-cell align-left">CEF</td>
						<td className="font-medium bg-cell align-left">CEP</td>
						<td className="font-medium bg-cell align-left">CEF</td>
						<td className="font-medium bg-cell align-left">CEP</td>
						<td className="font-medium bg-cell align-left">CEF</td>
						<td className="font-medium bg-cell align-left">CEP</td>
						<td className="font-medium bg-cell align-left">CEF</td>
						<td className="font-medium bg-cell align-left">CEP</td>
					</tr>
					<tr>
						<td className="font-medium align-left">Conso [KWh/m2.an]</td>
						<td className="align-center bg-cell"><JsonSyntheseInput name="conso_cef_situation_initiale" value={conso_cef_situation_initiale} onUpdate={updateJsonSynthese} /></td>
						<td className="align-center bg-cell"><JsonSyntheseInput name="conso_cep_situation_initiale" value={conso_cep_situation_initiale} onUpdate={updateJsonSynthese} /></td>
						<td className="align-center bg-cell"><JsonSyntheseInput name="conso_cef_scenario_1" value={conso_cef_scenario_1} onUpdate={updateJsonSynthese} /></td>
						<td className="align-center bg-cell"><JsonSyntheseInput name="conso_cep_scenario_1" value={conso_cep_scenario_1} onUpdate={updateJsonSynthese} /></td>
						<td className="align-center bg-cell"><JsonSyntheseInput name="conso_cef_scenario_2" value={conso_cef_scenario_2} onUpdate={updateJsonSynthese} /></td>
						<td className="align-center bg-cell"><JsonSyntheseInput name="conso_cep_scenario_2" value={conso_cep_scenario_2} onUpdate={updateJsonSynthese} /></td>
						<td className="align-center bg-cell"><JsonSyntheseInput name="conso_cef_scenario_3" value={conso_cef_scenario_3} onUpdate={updateJsonSynthese} /></td>
						<td className="align-center bg-cell"><JsonSyntheseInput name="conso_cep_scenario_3" value={conso_cep_scenario_3} onUpdate={updateJsonSynthese} /></td>
					</tr>
					<tr>
						<td className="font-medium align-left">Gain [KWhEF/m2.an]</td>
						<td className="bg-3" colSpan="2"></td>
						<td colSpan="2" className="align-center bg-cell">{numberToString(jsonSynthese[`conso_cef_situation_initiale`] - jsonSynthese[`conso_cef_scenario_1`], 2, true) || ' '}</td>
						<td colSpan="2" className="align-center bg-cell">{numberToString(jsonSynthese[`conso_cef_situation_initiale`] - jsonSynthese[`conso_cef_scenario_2`], 2, true) || ' '}</td>
						<td colSpan="2" className="align-center bg-cell">{numberToString(jsonSynthese[`conso_cef_situation_initiale`] - jsonSynthese[`conso_cef_scenario_3`], 2, true) || ' '}</td>
					</tr>
					<tr>
						<td className="font-medium align-left">GES [KgCO2/m2.an]</td>
						{[
							'situation_initiale',
							'scenario_1',
							'scenario_2',
							'scenario_3'
						].map(name => `ges_${name}_3`)
							.map((name, index) => <td key={index} colSpan="2" className="align-center bg-cell">
								<JsonSyntheseInput name={name} value={jsonSynthese[name]} onUpdate={updateJsonSynthese} />
							</td>)
						}
						{/* 
						<td colSpan="2" className="align-center bg-cell">{ges_scenario_1}</td>
						<td colSpan="2" className="align-center bg-cell">{ges_scenario_2}</td>
						<td colSpan="2" className="align-center bg-cell">{ges_scenario_3}</td> */}
					</tr>
					<tr>
						<td className="font-medium align-left">Gain énergétique [%]</td>
						<td className="bg-3" colSpan="2"></td>
						<td colSpan="2" className="align-center bg-cell">{(Math.round(((jsonSynthese['conso_cep_situation_initiale'] - jsonSynthese['conso_cep_scenario_1']) / jsonSynthese['conso_cep_situation_initiale'] * 100) * 100)) / 100}</td>
						<td colSpan="2" className="align-center bg-cell">{(Math.round(((jsonSynthese['conso_cep_situation_initiale'] - jsonSynthese['conso_cep_scenario_2']) / jsonSynthese['conso_cep_situation_initiale'] * 100) * 100)) / 100}</td>
						<td colSpan="2" className="align-center bg-cell">{(Math.round(((jsonSynthese['conso_cep_situation_initiale'] - jsonSynthese['conso_cep_scenario_3']) / jsonSynthese['conso_cep_situation_initiale'] * 100) * 100)) / 100}</td>
					</tr>
					<tr>
						<td className="font-medium align-left">cep {'>'} 110 Kwh/an</td>
						<td className="bg-3" colSpan="2"></td>
						<td colSpan="2" className="align-center bg-cell">{jsonSynthese['conso_cep_scenario_1'] > 110 ? "OUI" : "NON"}</td>
						<td colSpan="2" className="align-center bg-cell">{jsonSynthese['conso_cep_scenario_2'] > 110 ? "OUI" : "NON"}</td>
						<td colSpan="2" className="align-center bg-cell">{jsonSynthese['conso_cep_scenario_3'] > 110 ? "OUI" : "NON"}</td>
					</tr>
				</tbody>
			</table>
			<br />
		</div>
	);
};

export default TablePage2_2;