import { toDateInput } from "dates"
import { isString, upperFirst } from "lodash"
import { getToken } from "utils/Auth"

export const getUserSyntheseName = user => `${!!user.firstname ? upperFirst(user.firstname) + ' ' + upperFirst(user.lastname) : user.email}`

export const urlParametersToJson = search => search
	.replace('?', '')
	.split('&')
	.reduce((prev, actual) => {
		const [key, value] = actual.split('=')
		return {
			...prev,
			[key]: value
		}
	}, {})

export const parseUser = (userToParse) => ({
	...userToParse,
	hasTva: userToParse.tva !== 0 ? true : false
})

const accessHeaders = {
	'Access-Control-Request-Headers': '*',
	'Access-Control-Request-Method': '*',
	'Access-Control-Allow-Origin': '*',
}

export const apiUrl = ''
const jsonHeaders = {
	'Accept': 'application/json',
	'Content-Type': 'application/json',
}
export const getHeaders = ({ headers = {}, multiform = false } = {}) => ({
	...(multiform ? {} : jsonHeaders),
	...headers,
	...accessHeaders,
	'x-access-token': getToken()
})

export const getImageUrl = (imageId, dateTime = false) =>
	apiUrl + `/image/visualisation/${imageId}${dateTime ? `?${global.Date.now()}` : ''}`
export const getImageUrlWithName = (filename, dateTime = false) =>
	apiUrl + `/image/name/visualisation/${filename}${dateTime ? `?${global.Date.now()}` : ''}`

export const isNumber = (nbr) => /^[0-9]+$/.test(String(nbr))

export const isNull = (value) =>
	value === null ||
	value === undefined ||
	value === 'NULL' ||
	value === 'undefined' ||
	value === 'null'
export const isUndefined = (required, value) => required && (value == null || value === '' || value === false)

export const messageFileTypes = {
	IMAGE: 0,
	AUDIO: 1,
	FILE: 2,
	DOCX: 3,
	PDF: 4,
}
export const reverseString = (str) => [...str].reverse().join('')

export const getFileExtension = (filename) => {
	const reversed = reverseString(filename)
	return reverseString(reversed.substr(0, reversed.indexOf('.'))).replaceAll(
		' ',
		''
	)
}
export const getFileTypeFromExtension = (extension) => {
	switch (extension) {
		case 'png':
		case 'PNG':
		case 'jpeg':
		case 'jpg':
		case 'JPG':
			return messageFileTypes.IMAGE
		case 'docx':
			return messageFileTypes.DOCX
		case 'pdf':
			return messageFileTypes.PDF
		case 'ogg':
			return messageFileTypes.AUDIO
		default:
			return messageFileTypes.FILE
	}
}

export const objectToFormData = (obj, rootName, ignoreList) => {
	const formData = new FormData()

	function appendFormData(data, root) {
		if (!ignore(root)) {
			root = root || ''
			if (data instanceof File) {
				formData.append(root, data)
			} else if (Array.isArray(data)) {
				for (let i = 0; i < data.length; i++) {
					appendFormData(data[i], root + '[' + i + ']')
				}
			} else if (typeof data === 'object' && data) {
				for (const key in data) {
					if (data.hasOwnProperty(key)) {
						if (root === '') {
							appendFormData(data[key], key)
						} else {
							appendFormData(data[key], root + '.' + key)
						}
					}
				}
			} else {
				if (data !== null && typeof data !== 'undefined') {
					formData.append(root, data)
				}
			}
		}
	}

	function ignore(root) {
		return (
			Array.isArray(ignoreList) &&
			ignoreList.some(function (x) {
				return x === root
			})
		)
	}

	appendFormData(obj, rootName)

	return formData
}
export const padTo2Digits = (num) => {
	return num.toString().padStart(2, '0');
}

export const formatDate = (date) => {
	return [
		padTo2Digits(date.getDate()),
		padTo2Digits(date.getMonth() + 1),
		date.getFullYear(),
	].join('/');
}
export const isDateFormat = (str) => /^([0-9\-/]+)$/.test(String(str))
export const isSameValueOrUndefined = (objectToCompare, key, value, undefinedAccepted = false) => {
	return (
		objectToCompare[key] == value ||
		(value == null && !undefinedAccepted) ||
		(isDateFormat(value) && toDateInput(objectToCompare[key]) === value)
	)
}

export const getOnlyUpdatedValues = (objectToCompare, values, undefinedAccepted = false) =>
	Object.entries(values).reduce(
		(prev, [key, value]) =>
			isSameValueOrUndefined(objectToCompare, key, value, undefinedAccepted)
				? prev
				: {
					...prev,
					[key]: value === undefined ? null : value,
				},
		{}
	)

export const parseFloatFromString = (str) => {
	const regex = /[\d,\.]+/;
	const match = str.match(regex);
	if (match) {
		const num = match[0].replace(",", ".");
		return parseFloat(num);
	}
	return NaN;
}

export const uniqueArrOfObjects = a => [...new Set(a.map(o => JSON.stringify(o)))].map(s => JSON.parse(s))

export const numberToString = (number, numberFloat, formatted = false) => {
	const newString = (isString(number) ? parseFloatFromString(number) : number)?.toFixed(numberFloat)?.replace('.', ',')
	if (numberFloat === 0) {
		const number = +newString
		return number.toLocaleString('fr')
	} else if (formatted) {
		const formattedNumber = parseFloatFromString(newString);
		if (!isNaN(formattedNumber)) {
			return formattedNumber.toLocaleString('fr');
		}
	}
	return newString
}

export const findLongestStringLength = (array) =>
	array.reduce((maxLength, string) => Math.max(maxLength, string.length), 0);

export const decodeUriFromObject = (object) => {
	const decodedObject = {}
	for (const key in object) {
		decodedObject[key] = decodeURIComponent(object[key])
	}
	return decodedObject
}