import { Form } from 'semantic-ui-react'
import Label from './Label'
import InfoInput from './InfoInput'
import { Autocomplete, TextField } from '@mui/material'
import { useMemo } from 'react';
import classes from './Form.module.scss'
import classNames from 'classnames';

const FormAutocomplete = ({
	id,
	name,
	placeholder,
	onChange,
	onBlur,
	maxLength = null,
	label,
	legend = null,
	required,
	error,
	silentRequired = false,
	initialValue,
	handleEffect,
	options: optionsFromParams = [], //[value]
	requiredLang,
	info,
	formError = false
}) => {

	const options = useMemo(() => optionsFromParams.map(option => ({ title: option })), [optionsFromParams])

	const onValueChange = (event, newValues) => handleEffect({
		target: { value: newValues.map(({ title }) => title) }
	})

	const isError = useMemo(() => formError && error && required, [error, formError, required])

	return (
		<Form.Field
			required={required}
			className={classNames(classes.field, classes.flex)}
		>
			{label ?
				<label className={classes.conformLabel} htmlFor={name + (initialValue ? '-' + initialValue : '')}>
					{label}
				</label> : null
			}

			<Autocomplete
				multiple
				id={name + (initialValue ? '-' + initialValue : '')}
				name={name}
				filterSelectedOptions
				options={options}
				getOptionLabel={(option) => option.title}
				onChange={onValueChange}
				renderInput={(params) => (
					<TextField
						{...params}
						variant="outlined"
						placeholder={placeholder || ''}
					/>
				)}
			/>

			{legend ? <p className={classes.legendInput}>{legend}</p> : null}
			{info ? <InfoInput info={info} /> : null}
			{required && error && !silentRequired ?
				<Label
					style={{ display: 'inline-table' }}
					display={requiredLang}
					pointing={'left'}
					color={'red'} /> : null
			}
		</Form.Field>
	)
}

export default FormAutocomplete
