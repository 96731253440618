import { useReducer, createContext } from 'react'
import reducer, { initialState } from './reducers'

const Store = createContext()

const Provider = ({ children, initialValues }) => {
  const [state, dispatch] = useReducer(reducer, initialValues || initialState)
  return <Store.Provider value={{ state, dispatch }}>{children}</Store.Provider>
}

export { Store, Provider }
