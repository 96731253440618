import { IconButton, Tooltip } from '@material-ui/core';
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import SouthIcon from '@mui/icons-material/South';
import classes from './Table.module.scss'

const ActionCase = ({
	line,
	duplicate,
	remove
}) => {
	return <td className={classes.actionCase}>
		<Tooltip title="Supprimer la ligne">
			<IconButton className={classes.remove} onClick={() => remove(line)}>
				<DeleteIcon />
			</IconButton>
		</Tooltip>
		<Tooltip title="Dupliquer la ligne">
			<IconButton className={classes.duplicate} onClick={() => duplicate(line)}>
				<SouthIcon />
			</IconButton>
		</Tooltip>
	</td>
};

export default ActionCase;