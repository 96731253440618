import { useCallback, useContext } from "react"
import { removeToken } from "utils/Auth"
import { Store } from "store/store"
import { useNavigate } from "react-router-dom"

export const useDisconnect = () => {
	const { dispatch } = useContext(Store)
	const navigate = useNavigate()

	const logout = useCallback(() => {
		dispatch({
			type: 'USER/UPDATE',
			payload: null
		})
		removeToken()
		navigate('/signIn')

	}, [dispatch, navigate])

	return {
		logout,
	}

}