import { parseFloatFromString } from 'helpers';
import { isString } from 'lodash';
import React, { useMemo, useState, useEffect, useCallback } from 'react';

const ProjectInput = ({ type = 'number', onUpdate = () => { }, name, value: valueReceived, onEnter = () => { } }) => {
	const [localValue, setLocalValue] = useState(valueReceived);

	useEffect(() => {
		setLocalValue(valueReceived);
	}, [valueReceived]);

	const value = useMemo(() => {
		if (!localValue) { return '' }
		if (isString(localValue)) {
			return parseFloatFromString(localValue.replace(/\s/gi, ''))
		}
		return localValue
	}, [localValue])

	const handleKeyDown = useCallback((event) => {
		if (event.key === 'Enter') {
			onEnter({ [name]: localValue });
		}
	}, [localValue, name, onEnter])

	const handleChange = useCallback((event) => {
		setLocalValue(event.target.value || 0);
		onUpdate(event.target.value || 0)
	}, [onUpdate])

	return (
		<input
			style={{ width: 75 }}
			type={type}
			onChange={handleChange}
			onKeyDown={handleKeyDown}
			value={value}
		/>
	);
};

export default ProjectInput;
