import { useUsers } from 'hooks/useUsers';
import React, { useMemo } from 'react';
import MenuNavigationComponent from 'components/MenuNavigationComponent';
import UsersTable from './UsersTable';
import classes from 'styles/classes.module.scss'

const Users = () => {
    const { users, AMOs, engineers, installateurs } = useUsers(true)

    const components = useMemo(() => [
        {
            title: 'Tous les utilisateurs',
            component: <UsersTable
                users={users}
            />
        },
        {
            title: 'AMO',
            component: <UsersTable
                users={AMOs}
            />
        },
        {
            title: 'Installateurs',
            component: <UsersTable
                users={installateurs}
            />
        },
        {
            title: 'Ingénieurs',
            component: <UsersTable
                users={engineers}
            />
        },
    ].filter(e => !!e), [users, AMOs, engineers, installateurs])


    return (
        <div className={classes.fullWidth}>
            <MenuNavigationComponent
                components={components}
            />
        </div>
    );
};

export default Users;