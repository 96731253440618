import classes from 'styles/classes.module.scss'
import MyTable from 'components/MyTable/MyTable';
import React, { useMemo } from 'react';
import Button from 'components/Button';
import moment from 'moment';
import { columnTypes } from 'components/MyTable/MyTable.constant';
import { getUserSyntheseName } from 'helpers';

const UserSynthesesTable = ({ users: usersReceived, title = '' }) => {
	const users = useMemo(() => usersReceived.map(user => ({
		...user,
		displayName: getUserSyntheseName({
			firstname: user.firstname,
			lastname: user.lastname,
			email: user.email
		}),
	})), [usersReceived])

	const columns = useMemo(() => [
		{ field: 'id', title: 'ID', render: ({ id }) => /*<Button link={`/synthese/${id}`}>{*/id/*}</Button>*/ },
		{ field: 'displayName', title: 'Utilisateur', type: columnTypes.TEXT },
		{ field: 'synthese', title: 'Synthese', type: columnTypes.TEXT },
		{
			title: 'Actions', type: columnTypes.TEXT, render: ({ id, synthese}) => <>
				<Button link={`/admin/user/${id}`}>Paramètres de l'utilisateur</Button>
				{!!synthese && <Button type='secondary' link={`/syntheses/${id}`}>Synthèses de l'utilisateur</Button>}
			</>
		},
		{ field: 'createdAt', title: 'Date de création', type: columnTypes.DATE, render: ({ createdAt }) => moment(createdAt).format('DD/MM/YYYY') },
	], [])

	return (
		<div style={{ maxWidth: "100%" }} className={classes.marginAuto}>
			<div style={{ maxWidth: "100%" }} className={classes.marginAuto}>
				<MyTable
					counter={true}
					columns={columns}
					data={users}
					title={title}
					options={{
						paging: true,
						pageSize: 50
					}}
				/>
			</div>
		</div>
	);
};

export default UserSynthesesTable;