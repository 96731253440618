import { useContext, useMemo } from 'react'

import { Store } from '../store/store'

export const useStoreUtils = () => {
	const { dispatch, state } = useContext(Store)

	const setLoadingOn = () => dispatch({
		type: 'LOADING_ON'
	})
	const setLoadingOff = () => dispatch({
		type: 'LOADING_OFF'
	})
	const setTableLoadingOn = () => dispatch({
		type: 'UPDATE_TABLE_LOADING', payload: true
	})
	const setTableLoadingOff = () => dispatch({
		type: 'UPDATE_TABLE_LOADING', payload: false
	})

	const isAdmin = useMemo(() => !!state.user?.admin, [state])
	const isSyntheseUser = useMemo(() => !!state.user?.synthese, [state])
	const isComptaUser = useMemo(() => !!!state.user?.synthese, [state])

	return {
		dispatch,
		state,
		setLoadingOn,
		setLoadingOff,
		setTableLoadingOn,
		setTableLoadingOff,
		isAdmin,
		isSyntheseUser,
		isComptaUser,
	}
}
