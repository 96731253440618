import moment from 'moment'
import { isDate } from 'lodash'
export const isValidDate = dateString => {
	const date = new Date(dateString);
	return date instanceof Date && !isNaN(date);
}

export const timezoneToInputLocalDateTime = (dateTmz) => moment(dateTmz).format("YYYY-MM-DDTHH:mm")
export const shortDateFormat = (ts, locale) =>
	moment(ts).format(locale === 'en' ? 'MM/DD' : 'DD/MM')

export const timeDateFormat = (date) => moment(date).format('DD-MM-YYYY HH:mm')
export const getDateFormatted = (date) => moment(date).format('YYYY-MM-DD')

export const timestamp2date = (ts) => new Date(Date.parse(ts))

export const stripFullTimezoneName = (dateStr) => dateStr.split('(')[0].trim()

export const timestampFromAPIToUnix = (date) => timestamp2unix(new Date(date))

export const dateObj2dateKey = (date) => date2unix(date)

export const timestamp2unix = (ts) => date2unix(Date.parse(ts))

export const date2unix = (date) => Math.floor(date.valueOf() / 1000)

export const moment2unix = (date) => date2unix(date.toDate())

export const addDays = (date, days) => {
	const result = new Date(date)
	result.setDate(result.getDate() + days)
	return result
}

export const timestamp2mili = (ts) => Math.floor(ts * 1000)

export const hasWeekends = ({ start, end }) => {
	if (isWeekend(start) || isWeekend(end)) {
		return true
	}
	return Math.abs(moment.unix(start).diff(moment.unix(end), 'days')) > 6
}

export const convertTimeZoneFromDate = (date) => {
	const tzoffset = new Date().getTimezoneOffset() * 60000 // offset in milliseconds
	const localISOTime = new Date(date * 1000 - tzoffset)
		.toISOString()
		.slice(0, -1)
	return localISOTime
}

export const onlyWeekends = (dates) => {
	for (const date of dates) {
		if (!isWeekend(date)) {
			return false
		}
	}
	return true
}

export const getWeekendDaysInRange = ({ start, end }) => {
	let dates = []
	let current = start

	while (current < end) {
		if (isWeekend(current)) {
			dates.push(current)
		}
		current = addDays(current, 1)
	}
	return dates
}

export const removeWeekends = (dates) =>
	dates.filter((date) => !isWeekend(date))

export const isWeekend = (date) => {
	const _date = moment.unix(date)
	const dayOfWeek = _date.day()
	return dayOfWeek === 0 || dayOfWeek === 6
}

export const dayDateFormatFromAPI = (date) =>
	moment.unix(timestampFromAPIToUnix(date)).format('dddd, MMMM Do YY')
export const dayDateFormat = (date, locale) =>
	moment.unix(date).locale(locale).format('dddd Do MMMM YYYY')
export const getDatesFromRecurrence = ({ start, end, onDays, repeats }) => {
	let dates = []
	let currentDate = moment(start)

	for (let i = 0; currentDate <= end; i++) {
		const currentWeekDay = moment(currentDate).isoWeekday()
		for (let j = 0; j < onDays?.length; j++) {
			if (currentWeekDay <= Number(onDays[j])) {
				dates.push(moment(currentDate).isoWeekday(onDays[j]))
			} else {
				dates.push(
					moment(currentDate).add(1, 'weeks').isoWeekday(onDays[j])
				)
			}
		}
		currentDate = moment(currentDate).add(repeats, 'weeks')
	}
	return dates
}

export const toDateInput = (date) => moment(new Date(date)).format('YYYY-MM-DD')

export const inDateRange = (date, startDate, endDate) =>
	startDate <= date && date <= endDate

export const isEqual = (d1, d2) => {
	return +d1 == +d2
}

export const isDateBetween = (date, startDate, endDate) => {
	return date >= startDate && date <= endDate;
};

export const utc2gmt = (dateFromPostgres) => {
	const unix = timestampFromAPIToUnix(dateFromPostgres)
	const converted = convertTimeZoneFromDate(unix)
	return converted
}

export const getEndWorkHourToday = () => {
	const today = new Date()
	today.setHours(23, 59, 59)
	return today
}

export const getEndOfDay = (date) => {
	date.setHours(23, 59, 59, 999);
	return date;
};

export const getEndOfMonth = (date) => {
	const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
	const endOfMonth = new Date(date.getFullYear(), date.getMonth(), lastDayOfMonth);
	return endOfMonth;
}

export const padTo2Digits = (num) => {
	return num.toString().padStart(2, '0');
}

export const formatDate = (date) => {
	return [
		padTo2Digits(date.getDate()),
		padTo2Digits(date.getMonth() + 1),
		date.getFullYear(),
	].join('/');
}

export const formatDateWithHour = (date) => {
	return [
		padTo2Digits(date.getDate()),
		padTo2Digits(date.getMonth() + 1),
		date.getFullYear(),
	].join('/') + ' ' + [
		padTo2Digits(date.getHours()),
		padTo2Digits(date.getMinutes()),
	].join(':');
}


export const sortDateArr = (dateArr = [new Date(), new Date()]) =>
	dateArr.sort((a, b) => a - b)


export const getTodayMidnight = () => {
	const d = new Date();
	d.setHours(0, 0, 0, 0);
	return d
}

export const getFirstDayOfMonth = date => new Date(date.getFullYear(), date.getMonth(), 1)

export const getLastDayOfMonth = date => new Date(date.getFullYear(), date.getMonth() + 1, 0)

export const getExtremesDatesOfMonth = (dateFromParams = null) => {
	const date = dateFromParams || new Date()
	const firstDay = getFirstDayOfMonth(date);
	const lastDay = getLastDayOfMonth(date)
	return { startDate: firstDay, endDate: lastDay }
}



export const compareDiffTZDates = (d1, d2) =>
	formatDate(new Date(convertTimeZoneFromDate(d1)))
	===
	formatDate(new Date(convertTimeZoneFromDate(d2)))

export const getMostRecentDate = (dates = []) => dates
	.map(d => isDate(d) ? d : new Date(d))
	.sort(
		(a, b) => b.getTime() - a.getTime()
	)[0]

export const getOlderDate = (dates = []) => dates
	.map(d => isDate(d) ? d : new Date(d))
	.sort(
		(a, b) => a.getTime() - b.getTime()
	)[0]

export const isFutureDate = date => date > new Date()
export const isFutureMonth = month => {
	const today = new Date()
	const actualMonth = today.getMonth() + 1
	return month > actualMonth
}
