export const projectTypes = {
	PROJECT: 0,
	AUDIT: 1,
}

export const projectProjectTypes = {
	BAO: 0,
	TH145: 3,
	MAR: 5,
}
export const auditTypes = {
	BAO: 0,
	CAPRENOV: 1,
	TRANSFERT_CAPRENOV_VERS_BAO: 2,
	TH145: 3,
	MAR: 5,
}
export const auditTypesDefaultPrice = {
	BAO: 550,
	CAPRENOV: 475,
	TRANSFERT_CAPRENOV_VERS_BAO: 100,
	TH145: 1200,
	MAR: 200,
}

export const projectTypesDefaultPrice = {
	BAO: 300,
	CAPRENOV: 300,
	TRANSFERT_CAPRENOV_VERS_BAO: 300,
	TH145: 350,
	MAR: 300,
}

export const statusTypes = {
	VISITED: 'Visité',
	PAYED: 'Payé',
	INVOICE_SENDING: 'Envoie en facturation'
}