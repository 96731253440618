import React, { useMemo } from 'react';
import { Input } from 'semantic-ui-react';
import { debounce } from 'lodash';

const SearchOldTable = ({ updateFilters = () => {} }) => {

    const handleSearchChange = (evt) => {
        const { value } = evt.target
        updateFilters(value)
    }

    const handleChange = useMemo(
        () => debounce(handleSearchChange, 500),
        [updateFilters]
    );

    return (
        <Input
            style={{
                height: 'fit-content',
                marginTop: 'auto'
            }}
            icon='search'
            placeholder={'Rechercher'}
            onChange={handleChange}
        />
    );
};

export default SearchOldTable;
