import { Autocomplete, TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

const FormAutocompleteSingle = ({
	options: optionsFromParams = [],
	valueUpdated = () => { },
	label,
	name,
	defaultValue = null,
}) => {
	const options = useMemo(() => optionsFromParams.map(option => ({ title: option })), [optionsFromParams]);
	const [value, setValue] = useState(null)

	useEffect(() => {
		if (value === null) {
			if (defaultValue && optionsFromParams.includes(defaultValue)) {
				return setValue({ title: defaultValue });
			}
		}
	}, [defaultValue, value, optionsFromParams])


	const onUpdate = useCallback(
		(event, newValue) => {
			valueUpdated({ [name]: newValue ? newValue.title : null });
			setValue(newValue)
		},
		[valueUpdated, name]
	);

	return (
		<div>
			<label>{label}</label>
			<Autocomplete
				disablePortal
				value={value}
				options={options}
				getOptionLabel={(option) => option.title}
				isOptionEqualToValue={(option, value) => option.title === value.title}
				onChange={onUpdate}
				renderInput={(params) => (
					<TextField
						{...params}
						variant="outlined"
					/>
				)}
			/>
		</div>
	);
};

export default FormAutocompleteSingle;
