import { Autocomplete, TextField } from '@mui/material';
import React, { useCallback, useMemo } from 'react';

const FormAutocompleteMulti = ({
	options: optionsFromParams = [],
	valueUpdated = () => { },
	label,
	name
}) => {

	const options = useMemo(() => optionsFromParams.map(option => ({ title: option })), [optionsFromParams])
	const onUpdate = useCallback(
		(event, newValues) =>
			valueUpdated({ [name]: newValues.map(({ title }) => title).join(', ') }),
		[valueUpdated, name])
	return (
		<div>
			<label>
				{label}
			</label>
			<Autocomplete
				multiple
				filterSelectedOptions
				options={options}
				getOptionLabel={(option) => option.title}
				onChange={onUpdate}
				renderInput={(params) => (
					<TextField
						{...params}
						variant="outlined"
					/>
				)}
			/>
		</div>
	);
};

export default FormAutocompleteMulti;