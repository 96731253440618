import { useCallback, useMemo, useState } from 'react'
import FormComponent from 'components/Form/Form'
import { formatDate } from 'helpers'
import { isEmpty, upperFirst } from 'lodash'
import { energyTypes } from 'views/homeCompta/energy.constant'
import { toDateInput } from 'dates'
import FormAutocompleteMulti from './FormAutocompleteMulti'
import toast from 'components/toast/toast'
import classes from 'styles/classes.module.scss'
import classNames from 'classnames'
import FormAutocompleteSingle from './FormAutocompleteSingle'
import GoogleMapSearchBar from './GoogleMapSearchBar'

const auditeurs = [
	'Issam Saadaoui',
	'Rachida Mchirgui',
	'Kuetijo Léa',
	'Edson Nichols',
	'Mohamed Ali Belazi',
	'Idriss Issak Youssouf',
]

const isolationTypes = [
	'Aucune isolation',
	'Qualité de l\'isolation faible',
	'Qualité de l\'isolation faible moyenne',
]

const companies = [
	'AS CONSULTING',
	'PRIME EVOLUTION',
	'AMOGAIA',
]

const years = [
	'2023',
	'2022',
]
const chauffagesDappoint = [
	'Cheminée',
	'Poêle à bois',
	'Insert à bois',
]
const capRenovVersions = [
	'2023/01/05 / 2022.4.0',
	'2022/01/03 / 2022.0.0',
]
const BAOVersions = [
	// 'V2.0.72, 27/06/2023',
	'V2.0.69, 06/04/2023',
	'V.2.0.51, 18/01/2022',
]

const classeEnergetiquesTypes = ["A", "B", "C", "D", "E", "F", "G"]

const plancherBasTypes = [
	"Garage",
	"Sous-sol",
	"Cave",
	"Terre-plein",
	'Vide sanitaire'
]

const plancherHautTypes = [
	"Combles Perdus",
	"Combles aménagés/aménageables",
	"Toiture terrasse"
]

const FormToSend = ({ onSent = async () => { }, res = {} }) => {
	const [address, setAddress] = useState([])
	const [planchers, setPlanchers] = useState({})
	const [classeEnergetiques, setClasseEnergetiques] = useState({})
	const isBao = useMemo(() => !!res.isBao, [res])
	const classeEnergetiquesTypesMemo = useMemo(() => classeEnergetiquesTypes, [])
	const plancherBasTypesMemo = useMemo(() => (res.jsonSynthese || {}).type_plancher_bas ?
		[upperFirst((res.jsonSynthese || {}).type_plancher_bas), ...plancherBasTypes] : plancherBasTypes,
		[res])
	const plancherHautTypesMemo = useMemo(() => (res.jsonSynthese || {}).type_plancher_haut ?
		[upperFirst((res.jsonSynthese || {}).type_plancher_haut), ...plancherHautTypes] : plancherHautTypes,
		[res])

	const inputs = useMemo(() => [
		{
			type: 'text',
			name: 'reference',
			label: 'Reference',
			value: res.jsonSynthese.reference,
			required: true,
			silentRequired: true,
		},
		{
			type: 'date',
			name: 'date_visite',
			label: 'Date de visite',
			required: true,
			silentRequired: true,
		},
		{
			type: 'date',
			name: 'date_rapport',
			label: 'Date du rapport',
			value: toDateInput(new Date()),
			required: true,
			silentRequired: true,
			// ...(isBao ? { min: '2023-06-27' } : {})
		},

		...(isBao ? [/*...[
			'situation_initiale',
			'scenario_1',
			'scenario_2',
			'scenario_3'
		].map(name => `ges_${name}_3`)
			.map((name, index) => ({
				type: 'number',
				name,
				label: `Valeur du GES 3 usages ${index === 0 ? 'de la situation initiale' : `du scénario ${index}`}`,
				value: (res.jsonSynthese || {})[name],
				initialValue: (res.jsonSynthese || {})[name],
				required: true,
				silentRequired: true,
			})),*/ ...[
				/*{
					type: 'number',
					name: 'isolation_mur_exterieur',
					label: `Valeur en m2 des murs intérieurs et extérieurs`,
					value: (res.jsonSynthese || {}).isolation_mur_exterieur || 0,
					initialValue: (res.jsonSynthese || {}).isolation_mur_exterieur || 0,
					required: true,
					silentRequired: true,
				},
				// {
				// 	type: 'number',
				// 	name: 'isolation_mur_interieur',
				// 	label: `Valeur en m2 des murs intérieurs`,
				// 	value: (res.jsonSynthese || {}).isolation_mur_interieur || 0,
				// 	initialValue: (res.jsonSynthese || {}).isolation_mur_interieur || 0,
				// 	required: true,
				// 	silentRequired: true,
				// },
				{
					type: 'number',
					name: 'plafond_m2',
					label: `Valeur en m2 du plafond`,
					value: (res.jsonSynthese || {}).plafond_m2 || 0,
					initialValue: (res.jsonSynthese || {}).plafond_m2 || 0,
					required: true,
					silentRequired: true,
				},
				{
					type: 'number',
					name: 'plancher_m2',
					label: `Valeur en m2 du plancher`,
					value: (res.jsonSynthese || {}).plancher_m2 || 0,
					initialValue: (res.jsonSynthese || {}).plancher_m2 || 0,
					required: true,
					silentRequired: true,
				},*/
				// {
				// 	type: 'autocompleteMulti',
				// 	name: 'type_plancher_haut',
				// 	label: `Type de plancher haut`,
				// 	required: true,
				// 	silentRequired: true,
				// 	options: plancherHautTypesMemo
				// },
				// {
				// 	type: 'autocompleteMulti',
				// 	name: 'type_plancher_bas',
				// 	label: `Type de plancher bas`,
				// 	required: true,
				// 	silentRequired: true,
				// 	options: plancherBasTypesMemo
				// },
				{
					type: 'text',
					name: 'adresse_front',
					label: `Adresse`,
					value: address[0] || '',
					initialValue: '',
					required: true,
					silentRequired: true,
				},
				{
					type: 'text',
					name: 'code_postal_front',
					label: `Code postal`,
					value: address[1] || '',
					initialValue: '',
					required: true,
					silentRequired: true,
				},
				{
					type: 'text',
					name: 'ville_front',
					label: `Ville`,
					value: address[2] || '',
					initialValue: '',
					required: true,
					silentRequired: true,
				},
			]]
			: [/*({
				type: 'select',
				name: 'situationInitialeEnergie',
				label: 'Quel type d’énergie est concerné par le logement (Chauffage) ?',
				required: true,
				silentRequired: true,
				value: '0',
				options: energyTypes.map((energyType, index) => ({
					key: String(index),
					value: String(index),
					text: energyType
				})),
			},
			{
				type: 'select',
				name: 'situationInitialeEnergieECS',
				label: 'Quel type d’énergie est concerné par le logement (ECS) ?',
				required: true,
				silentRequired: true,
				value: '0',
				options: energyTypes.map((energyType, index) => ({
					key: String(index),
					value: String(index),
					text: energyType
				})),
			},*/ {
					type: 'number',
					name: 'isolation_mur_exterieur',
					label: `Valeur en m2 des murs intérieurs et extérieurs`,
					value: (res.jsonSynthese || {}).isolation_mur_exterieur || 0,
					initialValue: (res.jsonSynthese || {}).isolation_mur_exterieur || 0,
					required: true,
					silentRequired: true,
				}, {
					type: 'number',
					name: 'scop_value_PAC',
					label: 'Valeur du scop de la pompe à chaleur Air/Eau (laisser comme tel si inexistant)',
					value: 3.2,
					initialValue: 3.2,
					required: true,
					silentRequired: true,
				},
				{
					type: 'number',
					name: 'scop_value_PAC_air_air',
					label: 'Valeur du scop de la pompe à chaleur AIR/AIR (laisser comme tel si inexistant)',
					value: 4,
					initialValue: 4,
					required: true,
					silentRequired: true,
				},
				{
					type: 'number',
					name: 'scop_value_thermo',
					label: 'Valeur du cop du chauffe-eau thermodynamique (laisser comme tel si inexistant)',
					value: 3.1,
					initialValue: 3.1,
					required: true,
					silentRequired: true,
				},
				{
					type: 'number',
					name: 'scop_value_PAC_air_air_monosplit',
					label: 'Valeur du scop de la pompe à chaleur Air/Air MonoSplit (laisser comme tel si inexistant)',
					value: 4,
					initialValue: 4,
					required: true,
					silentRequired: true,
				},
				{
					type: 'number',
					name: 'm2_value_PAC_air_air_monosplit',
					label: 'Valeur des m2 concernés pour la pompe à chaleur Air/Air MonoSplit (laisser comme tel si inexistant)',
					value: 130,
					initialValue: 130,
					required: true,
					silentRequired: true,
				}]),
		(res.jsonSynthese.chauffage_apoint_nom_situation_initiale === 'Bois dur' ? {
			type: 'select',
			name: 'chauffageAppointName',
			label: 'Nom du chauffage d\'appoint',
			required: true,
			silentRequired: true,
			value: '0',
			options: chauffagesDappoint.map((energyType, index) => ({
				key: String(index),
				value: String(index),
				text: energyType
			})),
		} : null),
		/*!isBao && */{
			type: 'select',
			name: 'capRenovVersion',
			label: 'Version logiciel',
			required: true,
			silentRequired: true,
			value: '0',
			options: years.map((energyType, index) => ({
				key: String(index),
				value: String(index),
				text: energyType
			})),
		},
		{
			type: 'select',
			name: 'company',
			label: 'Société',
			required: true,
			silentRequired: true,
			value: '2',
			options: companies.map((energyType, index) => ({
				key: String(index),
				value: String(index),
				text: energyType
			})),
		},
	].filter(e => !!e), [isBao, res.jsonSynthese, address])

	console.log('res.jsonSynthese', res.jsonSynthese)
	const [updatedValues, setUpdatedValues] = useState({})

	const onUpdate = useCallback((values) => {
		setUpdatedValues(values)
	}, [setUpdatedValues])

	const sendForm = useCallback(async (values) => {
		if (!isBao) {
			// values.situationInitialeEnergie = energyTypes[values.situationInitialeEnergie]
			// values.situationInitialeEnergieECS = energyTypes[values.situationInitialeEnergieECS]
			values.scop_value_PAC = String(values.scop_value_PAC).replace('.', ',')
			values.scop_value_PAC_air_air = String(values.scop_value_PAC_air_air).replace('.', ',')
			values.scop_value_thermo = String(values.scop_value_thermo).replace('.', ',')
			values.scop_value_PAC_air_air_monosplit = String(values.scop_value_PAC_air_air_monosplit).replace('.', ',')
			values.m2_value_PAC_air_air_monosplit = String(values.m2_value_PAC_air_air_monosplit).replace('.', ',')
			values.isolation_mur_interieur = values.isolation_mur_exterieur
		} else {
			if (isEmpty(planchers) || isEmpty(planchers.type_plancher_haut) || isEmpty(planchers.type_plancher_bas)) {
				return toast.error('Vous devez selectionner au moins 1 plancher haut et 1 plancher bas')
			}
			// values.type_plancher_haut = values.type_plancher_haut.join(', ')
			// values.type_plancher_bas = values.type_plancher_bas.join(', ')
		}
		if (res.jsonSynthese.chauffage_apoint_nom_situation_initiale === 'Bois dur') {
			values.chauffageAppointName = chauffagesDappoint[values.chauffageAppointName]
		}
		values.date_visite = formatDate(new Date(values.date_visite))
		values.company = Number(values.company)
		if (values.company === 1 && new Date(values.date_rapport) < new Date('2023/06/27')) {
			return toast.error('Veuillez selectionner une date de rapport supérieure au 27/06/2023')
		}
		values.date_rapport = formatDate(new Date(values.date_rapport))
		values.capRenovVersion = (res.isBao ? BAOVersions : capRenovVersions)[values.capRenovVersion]
		if (values.company === 1) {
			values.capRenovVersion = 'V2.0.72, 27/06/2023'
		}
		if (values.company === 1 && isEmpty(planchers.auditeur)) {
			return toast.error('Veuillez choisir un auditeur')
		}
		values.auditeur = values.company === 0 ? 'Adam Safwat'
			: values.company === 2 ? 'Jérôme Alba'
				: (planchers.auditeur || 'Issam Saadoui')

		if (values.reference.length !== 10) {
			return toast.error('La référence doit être de 10 caractères.')
		}
		values.reference = (values.capRenovVersion === '2022.0.0 / 2022/01/03'
			? 'Audit V2 '
			: (!!res.jsonSynthese.restitution && !values.capRenovVersion.includes('2022') ? 'v2 ' : ''))
			+ values.reference
		return onSent({ ...values, ...planchers, ...classeEnergetiques })
	}, [res, onSent, isBao, planchers, classeEnergetiques])

	return (
		<div className={classes.fullWidth}>
			<GoogleMapSearchBar setAddress={setAddress} />
			<div className={classNames(classes.fullWidth, classes.centeredContainer, classes.mt1)}>
				<div className={classes.flex}>
					<div>
						{Number(updatedValues.company) == 1 && <FormAutocompleteSingle
							{...{
								name: 'auditeur',
								label: `Auditeur`,
								options: auditeurs,
							}}
							valueUpdated={obj => setPlanchers(planchers => ({ ...planchers, ...obj }))}
						/>}
						<FormComponent
							inputs={inputs}
							sendForm={sendForm}
							centered={true}
							responsive={false}
							segment={false}
							messages={{ submit: 'Convertir en rapport d\'audit' }}
							onUpdate={onUpdate}
						/>
					</div>
					{isBao && <div style={{ width: 250 }}>
						<FormAutocompleteSingle
							{...{
								name: 'type_isolation_combles',
								label: `Type d'isolation combles`,
								options: isolationTypes,
							}}
							valueUpdated={obj => setPlanchers(planchers => ({ ...planchers, ...obj }))}
						/>
						<FormAutocompleteSingle
							{...{
								name: 'type_isolation_plancher_bas',
								label: `Type d'isolation plancher bas`,
								options: isolationTypes,
							}}
							valueUpdated={obj => setPlanchers(planchers => ({ ...planchers, ...obj }))}
						/>
						<FormAutocompleteMulti
							{...{
								name: 'type_plancher_haut',
								label: `Type de plancher haut`,
								options: plancherHautTypesMemo,
							}}
							valueUpdated={obj => setPlanchers(planchers => ({ ...planchers, ...obj }))}
						/>
						<FormAutocompleteMulti
							{...{
								name: 'type_plancher_bas',
								label: `Type de plancher bas`,
								options: plancherBasTypesMemo,
							}}
							valueUpdated={obj => setPlanchers(planchers => ({ ...planchers, ...obj }))}

						/>
						<br />
						<FormAutocompleteSingle
							defaultValue={res.jsonSynthese.classe_energetique_3cl_situation_initiale}
							{...{
								name: 'classe_energetique_3cl_situation_initiale',
								label: `Classe énergétique 3CL situation initiale`,
								options: classeEnergetiquesTypesMemo,
							}}
							valueUpdated={obj => setClasseEnergetiques(classeEnergetiques => ({ ...classeEnergetiques, ...obj }))}

						/>
						<br />
						<FormAutocompleteSingle
							defaultValue={res.jsonSynthese.classe_energetique_3cl_scenario_1}
							{...{
								name: 'classe_energetique_3cl_scenario_1',
								label: `Classe énergétique 3CL scénario 1`,
								options: classeEnergetiquesTypesMemo,
							}}
							valueUpdated={obj => setClasseEnergetiques(classeEnergetiques => ({ ...classeEnergetiques, ...obj }))}

						/>
						<br />
						<FormAutocompleteSingle
							defaultValue={res.jsonSynthese.classe_energetique_3cl_scenario_2}
							{...{
								name: 'classe_energetique_3cl_scenario_2',
								label: `Classe énergétique 3CL scénario 2`,
								options: classeEnergetiquesTypesMemo,
							}}
							valueUpdated={obj => setClasseEnergetiques(classeEnergetiques => ({ ...classeEnergetiques, ...obj }))}

						/>
						<br />
						<FormAutocompleteSingle
							defaultValue={res.jsonSynthese.classe_energetique_3cl_scenario_3}
							{...{
								name: 'classe_energetique_3cl_scenario_3',
								label: `Classe énergétique 3CL scénario 3`,
								options: classeEnergetiquesTypesMemo,
							}}
							valueUpdated={obj => setClasseEnergetiques(classeEnergetiques => ({ ...classeEnergetiques, ...obj }))}

						/>
					</div>}
				</div>
			</div>
		</div>
	)
}

export default FormToSend
