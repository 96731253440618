import { Autocomplete, TextField } from '@mui/material';
import { findLongestStringLength } from 'helpers';
import React, { useMemo } from 'react';

const JsonSyntheseSelect = ({ onUpdate = () => { }, name, value, options: optionsReceived = [] }) => {
	const options = useMemo(() => optionsReceived.includes(value)
		? optionsReceived
		: [value, ...optionsReceived]
		, [optionsReceived, value])

		const minWidth = useMemo(() => findLongestStringLength(options) *10,[options])
	return (
		<Autocomplete
			id={"combo-box-demo" + name}
			options={options}
			sx={{ minWidth }}
			freeSolo
			includeInputInList
			renderInput={(params) => <TextField
				{...params}
				label=""
				autoComplete="off"
				InputProps={{
					...params.InputProps,
					style: { width: 'auto' },
				}} />}
			onChange={(evt, value) => onUpdate({ [name]: value })}
			value={value}
		/>
	);
};

export default JsonSyntheseSelect;