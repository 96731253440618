import React from 'react';
import classes from './Form.module.scss'

const InvalidEmailComponent = () => {
    return (
        <div className={classes.invalidEmailText}>
            {'Email invalide.'}
        </div>
    );
};

export default InvalidEmailComponent;