import React, { useState, useEffect, useCallback } from 'react';
import { Autocomplete, TextField, CircularProgress, ListItem, ListItemText } from '@mui/material';
import { debounce } from 'lodash';

const GoogleMapSearchBar = ({
	setAddress = () => { }
}) => {
	const [manualInputValue, setManualInputValue] = useState(''); // Nouveau état pour garder la valeur tapée

	const [inputValue, setInputValue] = useState('');
	const [selectedPlace, setSelectedPlace] = useState();
	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);

	const fetchPlaces = useCallback(
		(query) => {
			setLoading(true);
			const service = new window.google.maps.places.AutocompleteService();

			service.getPlacePredictions({ input: query }, (predictions, status) => {
				setLoading(false);

				if (status === window.google.maps.places.PlacesServiceStatus.OK) {
					setOptions(predictions);
				}
			});
		},
		[setOptions, setLoading]
	);

	useEffect(() => {
		if (inputValue === '') {
			setOptions([]);
			return;
		}

		fetchPlaces(inputValue);
	}, [inputValue, fetchPlaces]);

	const debounceInputValue = useCallback(debounce((event, newInputValue) => {
		setInputValue(!!newInputValue ? newInputValue : '');
	}, 500), [])

	const getPlaceDetails = (placeId) => new Promise((resolve, reject) => {
		const service = new window.google.maps.places.PlacesService(document.createElement('div'));
		service.getDetails({ placeId, fields: ['address_components'] }, (result, status) => {
			if (status === window.google.maps.places.PlacesServiceStatus.OK) {
				resolve(result);
			} else {
				resolve({})
			}
		});
	})

	const onSelectPlace = useCallback(async (event, newValue) => {
		if (newValue) {
			getPlaceDetails(newValue.place_id)
				.then(details => {
					let streetNumber, street, city, postalCode, country;

					details.address_components.forEach(component => {
						if (component.types.includes('street_number')) streetNumber = component.long_name;
						if (component.types.includes('route')) street = component.long_name;
						if (component.types.includes('locality')) city = component.long_name;
						if (component.types.includes('postal_code')) postalCode = component.long_name;
						if (component.types.includes('country')) country = component.long_name;
					});

					const formattedStreet = `${streetNumber || ''} ${street || ''}`.trim();
					const addressArray = [formattedStreet, postalCode, city, country];
					setAddress(addressArray);
					newValue.full_address = newValue.description
					setSelectedPlace(newValue);
					setManualInputValue(newValue.description); // Réinitialiser si une option est sélectionnée
				})
		} else {
			setSelectedPlace(newValue);
		}
	}, [setSelectedPlace, setManualInputValue, setAddress]);

	return (
		<Autocomplete
			value={selectedPlace || null}
			style={{ width: '100%' }}
			options={options}
			getOptionLabel={(option) => option.description}
			filterOptions={(x) => x}
			onInputChange={(event, newInputValue, reason) => {
				if (reason !== 'reset') {
					setManualInputValue(newInputValue); // Garder la valeur tapée
				}
				debounceInputValue(event, newInputValue);
			}}
			onChange={onSelectPlace}
			inputValue={manualInputValue} // Utiliser la valeur tapée
			renderInput={(params) => (
				<TextField
					{...params}
					label="Recherchez une adresse"
					variant="outlined"
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<React.Fragment>
								{loading ? <CircularProgress color="inherit" size={20} /> : null}
								{params.InputProps.endAdornment}
							</React.Fragment>
						),
					}}
				/>
			)}
			renderOption={(props, option) => (
				<ListItem {...props}>
					<ListItemText primary={option.structured_formatting?.main_text} secondary={option.structured_formatting?.secondary_text} />
				</ListItem>
			)}
		/>
	);
};

export default GoogleMapSearchBar;
