export const tkeys = {
	PUISSANCE: 0,
	COP: 1,
	SCOP: 2,
	M2: 3,
	M2_A_ISOLER: 4,
	M2_CAPTEUR_SOLAIRE: 5,
	ETAS: 6,
	Q4: 7,
	VOLUME: 8
}

export const travauxTypes = {
	"PAC Air/Air": [tkeys.PUISSANCE, tkeys.COP],
	"PAC Air/Air MonoSplit": [tkeys.PUISSANCE, tkeys.COP, tkeys.M2],
	"PAC Air/Eau (ETAS ≥ 111%)": [tkeys.PUISSANCE, tkeys.COP, tkeys.ETAS],
	"PAC Air/Eau (ETAS ≥ 126%)": [tkeys.PUISSANCE, tkeys.COP, tkeys.ETAS],
	"PAC Air/Eau (7/55°C)": [tkeys.PUISSANCE, tkeys.COP, tkeys.ETAS],
	"PAC Air/Eau Auto-Adaptative": [tkeys.PUISSANCE, tkeys.COP, tkeys.ETAS],
	"PAC Air/Eau (7/35°C)": [tkeys.PUISSANCE, tkeys.COP, tkeys.ETAS],
	"PAC Air/Eau Auto-Adaptative (7/35°C)": [tkeys.PUISSANCE, tkeys.COP, tkeys.ETAS],
	"Chauffage bois existant supprimé": [],//[],
	"Chauffage bois existant conservé": [],//[],
	"Installation d'une régulation centralisée": [],//[],
	"Isolation du circuit de distribution": [],//[tkeys.M2_A_ISOLER],
	"Chauffe-eau ThermoDynamique (7/55°C)": [tkeys.COP, tkeys.VOLUME],
	"Chauffe-eau Solaire": [tkeys.COP],
	"Isolation des combles perdus": [tkeys.M2_A_ISOLER],
	"Isolation des rampants": [tkeys.M2_A_ISOLER],
	"Isolation du plancher bas sur espace non chauffé": [tkeys.M2_A_ISOLER],
	"Isolation des murs par l'intérieur": [tkeys.M2_A_ISOLER],
	"Isolation des murs par l'extérieur": [tkeys.M2_A_ISOLER],
	"Mise en place d'une VMC double flux": [],//[],
	"Poele à granulé": [],//[],
	"Traitement des points singuliers": [],//[tkeys.Q4],
}

export const coutTravaux = {
	'PAC Air/Air': {
		title: 'PAC: PAC Air/Air',
		description: "Installation d'une pompe à chaleur Air/Air",
		price: ''
	},
	'PAC Air/Air MonoSplit': {
		title: 'PAC Air/Air MonoSplit',
		description: "Installation d'une pompe à chaleur Air/Air MonoSplit",
		price: ''
	},
	'PAC Air/Eau (ETAS ≥ 111%)': {
		title: 'PAC: PAC Air/Eau',
		description: "Installation d'une pompe à chaleur Air/Eau",
		price: '€17 000'
	},
	"PAC Air/Eau (ETAS ≥ 126%)": {
		title: 'PAC: PAC Air/Eau',
		description: "Installation d'une pompe à chaleur Air/Eau",
		price: '€17 000'
	},
	"PAC Air/Eau (7/55°C)": {
		title: 'PAC: PAC Air/Eau',
		description: "Installation d'une pompe à chaleur Air/Eau",
		price: '€17 000'
	},
	"PAC Air/Eau Auto-Adaptative": {
		title: 'PAC: PAC Air/Eau',
		description: "Installation d'une pompe à chaleur Air/Eau",
		price: '€17 000'
	},
	"PAC Air/Eau (7/35°C)": {
		title: 'PAC: PAC Air/Eau',
		description: "Installation d'une pompe à chaleur Air/Eau",
		price: '€17 000'
	},
	'Chauffage bois existant supprimé': {
		title: 'Chauffage bois existant supprimé',
		description: '',
		price: ''
	},
	'Chauffage bois existant conservé': {
		title: 'Chauffage bois existant conservé',
		description: '',
		price: ''
	},
	"Installation d'une régulation centralisée": {
		title: 'Régulation Centralisée',
		description: "Ajout d’un thermostat de régulation de la température",
		price: '€400'
	},
	"Isolation du circuit de distribution": {
		title: 'Circuit de distribution',
		description: "Isolation des circuits hydrauliques",
		price: '€1 200'
	},
	"Chauffe-eau ThermoDynamique (7/55°C)": {
		title: 'Chauffe-eau ThermoDynamique (7/55°C)',
		description: "Installation d'un chauffe-eau thermodynamique",
		price: '€5 000'
	},
	"Chauffe-eau Solaire": {
		title: 'Chauffe-eau Solaire',
		description: "Installation d'un chauffe-eau solaire",
		price: '€6 500'
	},
	"Isolation des combles perdus": {
		title: 'Isolation des combles perdus',
		description: 'R [m².K/W] ≥ 7',
		price: '45€/m2'
	},
	"Isolation des rampants": {
		title: 'Isolation des combles rampants',
		description: 'R [m².K/W] ≥ 6',
		price: '70€/m2'
	},
	"Isolation du plancher bas sur espace non chauffé": {
		title: 'Isolation du plancher bas sur espace non chauffé	',
		description: 'R [m².K/W] ≥ 3',
		price: "50€/m2"
	},
	"Isolation des murs par l'intérieur": {
		title: "Isolation des murs par l'intérieur",
		description: 'R [m².K/W] ≥ 3,7',
		price: '70€/m2'
	},
	"Isolation des murs par l'extérieur": {
		title: "Isolation des murs par l'extérieur",
		description: 'R [m².K/W] ≥ 3,7',
		price: "150€/m2"
	},
	"Mise en place d'une VMC double flux": {
		title: "Mise en place d'une VMC double flux",
		description: '',
		price: '€5 000'
	},
	"Poele à granulé": {
		title: 'Poele à granulé',
		description: '',
		price: ''
	},
	"Traitement des points singuliers": {
		title: "Ventilation",
		description: 'Traitement des points singuliers',
		price: ''
	},
}