import classNames from 'classnames';
import { timeDateFormat } from 'dates';
import React from 'react';
import classes from 'styles/classes.module.scss'
import { projectTypes } from './project.constant';

const ProjectComponent = ({
	type,
	id,
	cp,
	name,
	createdDate,
	auditDate,
	priceAuditor,
	priceInstallator,
	priceEngineer,
	kumac,
	auditorUser,
	regieUser,
	statusObj,
	engineerUser,
	auditType,
	similarTo = false
}) => {
	return (
		<div>
			{similarTo && <h2>{(type === projectTypes.AUDIT ? 'Audit' : 'Projet') + ' similaire'}</h2>}
			<div className={classNames(classes.flex, classes.gap1)}>
				<h3 className={classes.noMargin}>ID</h3>
				<span className={classes.alignSelfEnd}>{id}</span>
			</div>
			{type === projectTypes.AUDIT && <div className={classNames(classes.flex, classes.gap1)}>
				<h3 className={classes.noMargin}>Type d'audit</h3>
				<span className={classes.alignSelfEnd}>{auditType}</span>
			</div>}
			<div className={classNames(classes.flex, classes.gap1)}>
				<h3 className={classes.noMargin}>Code postal</h3>
				<span className={classes.alignSelfEnd}>{cp}</span>
			</div>
			<div className={classNames(classes.flex, classes.gap1)}>
				<h3 className={classes.noMargin}>Nom du client</h3>
				<span className={classes.alignSelfEnd}>{name}</span>
			</div>
			<div className={classNames(classes.flex, classes.gap1)}>
				<h3 className={classes.noMargin}>Date de création</h3>
				<span className={classes.alignSelfEnd}>{timeDateFormat(createdDate)}</span>
			</div>
			<div className={classNames(classes.flex, classes.gap1)}>
				<h3 className={classes.noMargin}>Date d'audit</h3>
				<span className={classes.alignSelfEnd}>{timeDateFormat(auditDate)}</span>
			</div>
			<div className={classNames(classes.flex, classes.gap1)}>
				<h3 className={classes.noMargin}>Auditeur</h3>
				<span className={classes.alignSelfEnd}>{auditorUser?.name}</span>
			</div>
			<div className={classNames(classes.flex, classes.gap1)}>
				<h3 className={classes.noMargin}>Régie</h3>
				<span className={classes.alignSelfEnd}>{regieUser?.name}</span>
			</div>
			<div className={classNames(classes.flex, classes.gap1)}>
				<h3 className={classes.noMargin}>Statut</h3>
				<span className={classes.alignSelfEnd}>{statusObj?.status}</span>
			</div>
			<div className={classNames(classes.flex, classes.gap1)}>
				<h3 className={classes.noMargin}>Ingénieur</h3>
				<span className={classes.alignSelfEnd}>{engineerUser?.name}</span>
			</div>
			<div className={classNames(classes.flex, classes.gap1)}>
				<h3 className={classes.noMargin}>Prix auditeur HT</h3>
				<span className={classes.alignSelfEnd}>{priceAuditor}</span>
			</div>
			<div className={classNames(classes.flex, classes.gap1)}>
				<h3 className={classes.noMargin}>Prix installateur HT</h3>
				<span className={classes.alignSelfEnd}>{priceInstallator}</span>
			</div>
			<div className={classNames(classes.flex, classes.gap1)}>
				<h3 className={classes.noMargin}>Prix ingénieur HT</h3>
				<span className={classes.alignSelfEnd}>{priceEngineer}</span>
			</div>
			<div className={classNames(classes.flex, classes.gap1)}>
				<h3 className={classes.noMargin}>Kumac</h3>
				<span className={classes.alignSelfEnd}>{kumac}</span>
			</div>
		</div>
	);
};

export default ProjectComponent;