import FormComponent from 'components/Form/Form';
import toast from 'components/toast';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collaboratorTypes } from './collaborator.constant';
import classes from 'styles/classes.module.scss'
import { useUsers } from 'hooks/useUsers';
import { tvaTypes } from './tva.constant';
import { useApi } from 'hooks/useApi';

const UserCreate = () => {
	const { createUser } = useApi()
    const { users, installateurs } = useUsers()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [values, setValues] = useState({ type: '0', projectPrice: 150, auditPrice: 0, tva: '0' })

    const create = async (infos) => {
        setLoading(true)
        try {
            if (!!users.find(user => user.name === infos.name)) {
                setLoading(false)
                return toast.error('Nom déjà utilisé.')
            }
            if (Number(infos.type) === collaboratorTypes.INSTALLATEUR
                && !!installateurs.find(user => user.code === infos.code)) {
                setLoading(false)
                return toast.error('Code déjà utilisé.')
            }
            if (Number(infos.tva) === tvaTypes.OUI) {
                infos.tva = 0.2
            }
            const user = await createUser(infos)
            toast.success('Collaborateur créé.')
            setLoading(false)
            navigate('/user/' + user.id)
        } catch (error) {
            setLoading(false)
            toast.error('Erreur durant la création du collaborateur: ' + error.message)
        }
    }


    const onUpdate = (values) => setValues(values)

    const inputs = useMemo(() => [
        {
            type: 'text',
            name: 'name',
            label: 'Nom',
            required: true,
            value: values.name,
            silentRequired: true,
        },
        {
            type: 'select',
            name: 'type',
            label: 'Type de collaborateur',
            required: true,
            silentRequired: true,
            value: values.type,
            options: Object
                .keys(collaboratorTypes)
                .map((collaboratorType, index) => ({
                    key: String(index),
                    value: String(index),
                    text: collaboratorType
                })),
        },
        {
            type: 'number',
            name: 'projectPrice',
            required: true,
            silentRequired: true,
            value: values?.projectPrice,
            label: 'Prix projet HT',
        },
        Number(values.type) === collaboratorTypes.INSTALLATEUR && {
            type: 'number',
            name: 'auditPrice',
            required: true,
            silentRequired: true,
            value: values?.auditPrice || 0,
            label: 'Prix audit HT',
        },
        Number(values.type)
        ===
        collaboratorTypes.INSTALLATEUR && {
            type: 'text',
            name: 'code',
            required: true,
            silentRequired: true,
            value: values.code,
            label: 'Code',
        },
        {
            type: 'select',
            name: 'tva',
            label: 'Est-il assujetti à la TVA ?',
            required: true,
            silentRequired: true,
            value: values.tva,
            options: Object
                .keys(tvaTypes)
                .map((tvaType, index) => ({
                    key: String(index),
                    value: String(index),
                    text: tvaType
                })),
        },
    ].filter(e => !!e), [values])


    return (
        <div className={classes.marginAuto}>
            <h2>Création du collaborateur</h2>
            <FormComponent
                loading={loading}
                inputs={inputs}
                sendForm={create}
                centered={true}
                responsive={false}
                segment={false}
                onUpdate={onUpdate}
            />
        </div>
    );
};

export default UserCreate;