import Button from 'components/Button';
import MenuNavigationComponent from 'components/MenuNavigationComponent';
import { useUser } from 'hooks/useUser';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import AddDeposit from './AddDeposit';
import { collaboratorTypes } from './collaborator.constant';
import DepositTable from './DepositTable';
import TotalPrice from './TotalPrice';
import UpdateUser from './UpdateUser';
import classes from './UserPage.module.scss'
import classNames from 'classnames';
import ApiTableNavigationProjects from './ApiTableNavigationProjects';

const UserPage = () => {
	const id = Number(useParams().userId)
	const {
		user,
		setUser,
		userTypeTitle,
		field,
		deposits,
		setDeposits,
		deposit,
		isDeletable,
		removeUser,
	} = useUser(id)

	const components = useMemo(() => [
		{
			title: 'Projets',
			component: <ApiTableNavigationProjects />,
			value: '0'
		},
		[collaboratorTypes.AMO, collaboratorTypes.INGENIEUR].includes(user?.type) && {
			title: 'Acomptes',
			component: <DepositTable
				deposits={deposits} setDeposits={setDeposits} />,
		},
		[collaboratorTypes.INSTALLATEUR, collaboratorTypes.INGENIEUR].includes(user?.type) && {
			title: 'Audits',
			component: <ApiTableNavigationProjects />,
			value: '1'
		},
	].filter(e => !!e), [deposits, setDeposits, user?.type])

	return (
		!!user && <div className={classNames(classes.marginAuto, classes.relative)}>
			{isDeletable && <Button
				type='danger'
				onClick={removeUser}
			>Supprimer l'{userTypeTitle}</Button>}
			<h2>Page de l'{userTypeTitle}</h2>
			<div className={classes.topContainer}>
				<UpdateUser
					userTypeTitle={userTypeTitle}
					user={user}
					setUser={setUser}
				/>
				<div>
					<TotalPrice field={field} user={user} deposit={deposit} />
					{[collaboratorTypes.AMO, collaboratorTypes.INGENIEUR].includes(user?.type) && <AddDeposit
						userId={id}
						setDeposits={setDeposits}
					/>}
				</div>
			</div>
			<MenuNavigationComponent
				queryName='type'
				components={components}
			/>
		</div>
	)
};

export default UserPage;