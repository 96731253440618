import { getUserSyntheseName } from 'helpers';
import { useApi } from 'hooks/useApi';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import SynthesesTable from 'views/homeCompta/SynthesesTable';

const UserSyntheses = () => {
	const { userId } = useParams()
	const { getUserSyntheses } = useApi()
	const [syntheses, setSyntheses] = useState([])

	useEffect(() => {
		getUserSyntheses(userId)
			.then(setSyntheses)
			.catch(e => console.log(e))
	}, [getUserSyntheses, userId])

	const user = useMemo(() => {
		if (syntheses.length > 0) {
			return syntheses[0].userSynthese
		}
		return null
	}, [syntheses])
	return (
		!!user && <SynthesesTable
			syntheses={syntheses}
			title={`Synthèses de ${getUserSyntheseName(user)}`}
		/>
	);
};

export default UserSyntheses;