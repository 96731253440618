import useTextHighlighter from 'hooks/useTextHighlighter';
import React from 'react';

const Counter = () => {
    const count = useTextHighlighter()

    return (
        !!count && <div>
            {count} éléments selectionnés
        </div>
    );
};

export default Counter;