import React, { useEffect, useMemo, useState } from 'react';
import { tkeys } from 'views/homeCompta/travaux.constant';

const KwInput = ({
	initialValue,
	kw,
	onChange = () => { },
	lineIndex
}) => {
	const key = useMemo(() => Object.entries(tkeys).find(([key, value]) => value === kw)[0], [kw])
	const [value, setValue] = useState(initialValue)

	useEffect(() => {
		setValue(initialValue)
		onChange({ target: { value: initialValue, name: key } })
	}, [initialValue, key])

	const beforeOnChange = (evt) => {
		setValue(evt.target.value)
		onChange(evt)
	}
	return (
		<>
			<label htmlFor={key + lineIndex}>{key.includes('M2') ? 'm²' : key}</label>
			<input
				id={key + lineIndex}
				name={key}
				type='number'
				onChange={beforeOnChange}
				{...(initialValue ? { value: value } : {})}
			/>
		</>
	);
};

export default KwInput;