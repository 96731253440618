import FormComponent from 'components/Form/Form';
import toast from 'components/toast';
import { timezoneToInputLocalDateTime } from 'dates';
import { getOnlyUpdatedValues } from 'helpers';
import { useStatuses } from 'hooks/useStatuses';
import { useUsers } from 'hooks/useUsers';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import UpdatableFieldFromList from './UpdatableFieldFromList';
import classes from 'styles/classes.module.scss'
import classNames from 'classnames';
import { auditTypes, projectTypes } from './project.constant';
import ProjectsTable from './ProjectsTable';
import Button from 'components/Button/Button';
import { useApi } from 'hooks/useApi';

const Project = () => {
	const { deleteProject, getProject, updateProject } = useApi()
	const navigate = useNavigate()
	const id = Number(useParams().id)
	const [project, setProject] = useState({})
	const { AMOs, installateurs, engineers } = useUsers()
	const { statuses } = useStatuses()
	const [loading, setLoading] = useState(false)
	const similarTo = useMemo(() => !!project.doublon && project.similarTo, [project])
	useEffect(() => {
		getProject(id)
			.then(setProject)
	}, [id])

	const removeDoublon = useCallback(async () => {
		try {
			await deleteProject(project.id)
			toast.success('Doublon supprimé.')
			navigate('/doublons')
		} catch (error) {
			console.log('error', error)
			toast.success('Erreur durant la suppression du doublon: ' + error.message)
		}
	}, [project, navigate])

	const inputs = useMemo(() => [
		{
			type: 'select',
			name: 'type',
			label: 'Type de projet',
			required: true,
			silentRequired: true,
			value: String(project.type),
			options: Object
				.keys(projectTypes)
				.map((projectType, index) => ({
					key: String(index),
					value: String(index),
					text: projectType
				})),
		},
		{
			type: 'select',
			name: 'auditType',
			label: 'Type d\'audit',
			value: project.auditType ? String(auditTypes[project.auditType]) : null,
			options: Object
				.keys(auditTypes)
				.map((projectType, index) => ({
					key: String(index),
					value: String(index),
					text: projectType
				})),
		},
		{
			type: 'text',
			name: 'cp',
			value: project.cp,
			label: 'Code postal',
		},
		{
			type: 'text',
			name: 'name',
			value: project.name,
			label: 'Nom du client',
			required: true,
			silentRequired: true,
		},
		{
			type: 'datetime-local',
			name: 'createdDate',
			value: timezoneToInputLocalDateTime(project.createdDate),
			label: 'Date de création',
		},
		{
			type: 'datetime-local',
			name: 'auditDate',
			value: timezoneToInputLocalDateTime(project.auditDate),
			label: 'Date d\'audit',
		},
		{
			type: 'number',
			name: 'priceAuditor',
			value: project.priceAuditor,
			label: 'Prix auditeur HT',
		},
		{
			type: 'number',
			name: 'priceInstallator',
			value: project.priceInstallator,
			label: 'Prix installateur HT',
		},
		{
			type: 'number',
			name: 'kumac',
			value: project.kumac,
			label: 'Kumac',
		},
		{
			type: 'number',
			name: 'priceEngineer',
			value: project.priceEngineer,
			label: 'Prix ingénieur HT',
		},
		{
			type: 'text',
			name: 'ticketKey',
			value: project.ticketKey,
			label: 'Clé ticket',
		},
		{
			type: 'textArea',
			name: 'notes',
			value: project.notes,
			label: 'Notes',
		},
	], [project])

	const update = async (values) => {
		setLoading(true)
		try {
			if (values.auditType) {
				values.auditType = Object.entries(auditTypes).find(([key, value]) => value === Number(values.auditType))[0]
			}
			const updatedValues = getOnlyUpdatedValues({
				...project,
				createdDate: timezoneToInputLocalDateTime(project.createdDate),
				auditDate: timezoneToInputLocalDateTime(project.auditDate)
			}, values, true)
			if (isEmpty(updatedValues)) {
				toast.error('Aucune info n\'a changé.')
				return
			}
			await updateProject(id, updatedValues)
			setProject(project => ({ ...project, ...updatedValues }))
			toast.success('Infos actualisées')
		} catch (error) {
			toast.error('Erreur durant l\'actualisation des infos :' + error.message)
			console.log('error', error)
			return
		} finally {
			setLoading(false)
		}
	}

	return (
		<>
			<div className={classNames(
				classes.marginAuto,
				// classes.flex,
				// classes.flexWrap
			)}>
				{!!similarTo && <div className={classes.mb2}>
					<h1>DOUBLON {project.type === projectTypes.PROJECT ? 'PROJET' : 'AUDIT'}</h1>
					<Button onClick={removeDoublon} type='black' >Supprimer le doublon</Button>
					<Button onClick={() => update({ doublon: false })} type='success' >Ce n'est plus un doublon</Button>
				</div>}
				<div className={classNames(
					classes.marginAuto,
					classes.flex,
					classes.flexWrap
				)}>
					<div>
						<h3>Informations {project.type === projectTypes.PROJECT ? 'du projet' : 'de l\'audit'}</h3>
						<FormComponent
							loading={loading}
							inputs={inputs}
							sendForm={update}
							centered={true}
							responsive={false}
							segment={false}
							messages={{ submit: 'Actualiser les infos' }}
						/>
					</div>
					<div className={
						classNames(classes.flex, classes.columnDirection, classes.flexWrap)
					}>
						<UpdatableFieldFromList
							name={'auditor'}
							list={AMOs}
							title={'Auditeur'}
							value={project.auditor}
							onUpdate={update}
						/>
						<UpdatableFieldFromList
							name={'regie'}
							list={installateurs}
							title={'Installateur'}
							value={project.regie}
							onUpdate={update}
						/>
						<UpdatableFieldFromList
							name={'engineer'}
							list={engineers}
							title={'Ingénieur'}
							value={project.engineer}
							onUpdate={update}
						/>
						<UpdatableFieldFromList
							noNavigate={true}
							name={'status'}
							list={statuses}
							title={'Statut'}
							value={project.status}
							onUpdate={update}
							labelProp={'status'}
						/>
					</div>
				</div>
			</div>
			{!!similarTo && <div>
				<h1 className={classes.taLeft}>{project.type === projectTypes.PROJECT ? 'Projet' : 'Audit'} ressemblant à ces {project.type === projectTypes.PROJECT ? 'projet' : 'audit'}s</h1>
				<ProjectsTable isAudit={project?.type === projectTypes.AUDIT} projects={similarTo} actionDisable />
			</div>}
		</>
	);
};

export default Project;