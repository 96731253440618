import { TextField } from '@material-ui/core';
import { Autocomplete } from '@mui/material';
import { upperFirst } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import './HeaderTable.scss'
import classes from '../../styles/classes.module.scss'
import classNames from 'classnames';

const FiltersTable = ({
    className = '',
    filters = {},
    updateFilters = () => { }
}) => {
    const key = useMemo(() => Object.keys(filters)[0], [filters])
    const values = useMemo(() => (filters[key] || { values: [] }).values || [], [key, filters])
    const title = useMemo(() => (filters[key] || { title: '' }).title || '', [filters, key])
    const selectValue = useCallback((event, newValue) => {
        updateFilters({ [key]: newValue });
    }, [key])

    return (
        <div className={classNames(classes.flex)}>
            <div classes={{ root: classes.alignSelf }}>
                {upperFirst(title)}
            </div>
            <Autocomplete
                className={'autocomplete' + className}
                multiple
                onChange={selectValue}
                disablePortal
                id="filter-table"
                options={values}
                sx={{ width: 250 }}
                renderInput={(params) => <TextField {...params} />}
            />
        </div>
    );
};

export default FiltersTable;