import React, { useMemo } from 'react';
import MenuNavigationComponent from 'components/MenuNavigationComponent/MenuNavigationComponent';
import classes from '../../styles/classes.module.scss'
import classNames from 'classnames';
import ApiTableProjects from './ApiTableProjects';
import { auditTypes, projectProjectTypes } from './project.constant';
import { useSearchParams } from 'react-router-dom';

const ApiTableNavigationProjects = () => {
	const [searchParams] = useSearchParams()
	const type = useMemo(() => searchParams.get('type'), [searchParams]) 
	const components = useMemo(() => [
		{
			title: `Tous les ${type == 1 ? 'audits' : 'projets'}`,
			component:
				<ApiTableProjects />
		},
		...Object.keys(type == 1 ? auditTypes : projectProjectTypes).map((title) => ({
			title,
			component: <ApiTableProjects />
		}))
	].filter(e => !!e), [type])


	return (
		<div className={classNames(classes.relative, classes.fullWidth, classes.flex, classes.columnDirection, classes.alignItemsLeft)}>
			<MenuNavigationComponent
				components={components}
				queryName='auditType'
			/>
		</div>
	);
};

export default ApiTableNavigationProjects;
