import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import classes from './Table.module.scss'
import KwInput from './KwInput';

const TitleCase = ({
	initialValues,
	isKw,
	title,
	updateKw = () => { },
	lineIndex,
	quantity = 1,
	updateQuantity = () => { },
	isBao = false
}) => {
	const [values, setValues] = useState({})
	useEffect(() => {
		updateKw(values, lineIndex)
	}, [values, lineIndex, updateKw])

	const handleInputChange = useCallback(
		({ target: { name, value } }) =>
			setValues(values => (value !== '' && value != null) ? {
				...values,
				[name]: String(value).replace('.', ',')
			} : Object.entries(values).reduce(
				(prev, [key, actualValue]) => ({
					...prev,
					...(key !== name ? { [key]: actualValue } : {})
				}), {}
			)), [setValues])

	return (
		<th
			{...(isKw ? { onClick: () => updateKw(lineIndex) } : {})}
			className={classNames(isKw && classes.isKw)}
		>
			<div className={classes.headerContainer}>
				<span>
					{isBao && <>
						{quantity > 1 && <span className={classes.plusMinusButton}
							onClick={() => updateQuantity(false)}
						>-</span>}
						<span className={classes.mr05}>{quantity}</span>
						<span className={classes.plusMinusButton}
							onClick={() => updateQuantity(true)}
						>+</span></>}
					{title}
				</span>
				{isBao && isKw && <span className={classes.isKwContainer}>
					{
						isKw.map((value, index) =>
							<KwInput
								key={index}
								kw={value}
								lineIndex={lineIndex}
								onChange={handleInputChange}
								initialValue={initialValues[index]}
							/>)
					}
				</span>}
			</div>
		</th>
	);
};

export default TitleCase;