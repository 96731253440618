import React, { useEffect, useMemo } from "react"
import "../../App.scss"
import { isAuthenticated } from '../../utils/Auth'
import { useNavigate } from "react-router-dom"
import FormComponent from "components/Form/Form"
import { useAuth } from "hooks/useAuth"

function SignIn() {
	let navigate = useNavigate()
	const { login } = useAuth()

	useEffect(() => {
		isAuthenticated() && navigate('/')
	}, [])


	const inputs = useMemo(() => [
		{
			type: 'text',
			name: 'email',
			label: 'Nom d\'utilisateur',
			required: true,
			silentRequired: true,
		},
		{
			type: 'password',
			name: 'password',
			label: 'Mot de passe',
			required: true,
			silentRequired: true,
		}
	], [])
	return (
		<div className="form-style">
			<h1>Connexion</h1>
			<FormComponent
				inputs={inputs}
				sendForm={login}
				centered={true}
				responsive={false}
				segment={false}
				messages={{ submit: 'Se connecter' }}
			/>
		</div>
	);
}

export default SignIn
