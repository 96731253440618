import Button from 'components/Button';
import React, { useCallback, useMemo, useState } from 'react';
import Case from './Case';
import classes from './Table.module.scss'
import { toast } from 'react-toastify';
import ActionCase from './ActionCase';
import { fakeForm } from 'components/constants/fakeForm.constant';
import TitleCase from './TitleCase';
import { coutTravaux, tkeys, travauxTypes } from 'views/homeCompta/travaux.constant';
import { numberToString } from 'helpers';
import { useApi } from 'hooks/useApi';

const responses = {
	AUCUN: 0,
	SCENARIO_1: 1,
	SCENARIO_2: 2,
	SCENARIO_3: 3,
	ALL: 4
}

const Cases = ({
	res: { extraCases, ...res },
	onFileDownloaded = () => { },
}) => {
	const { fetchApi } = useApi()
	const isBao = useMemo(() => !!res.isBao, [res])

	const getPuissanceAndCop = ({
		PUISSANCE,
		COP,
		SCOP,
		M2,
		M2_A_ISOLER,
		M2_CAPTEUR_SOLAIRE,
		ETAS,
		Q4,
		VOLUME
	}) => [
		PUISSANCE && `Puissance = ${numberToString(PUISSANCE, 2, true)}kW`,
		COP && `COP ≥ ${numberToString(COP, 2, true)}`,
		SCOP && `SCOP ≥ ${numberToString(SCOP, 2, true)}`,
		M2 && `${numberToString(M2, 2, true)}m²`,
		M2_A_ISOLER && `${numberToString(M2_A_ISOLER, 2, true)}m² à isoler`,
		M2_CAPTEUR_SOLAIRE && `Capteur Solaire ≥ ${numberToString(M2_CAPTEUR_SOLAIRE, 2, true)}m²`,
		ETAS && `ETAS ≥ ${numberToString(ETAS, 2, true)}%`,
		Q4 && `Q4 visé = ${numberToString(Q4, 2, true)}`,
		VOLUME && `VOLUME >= ${VOLUME}L`,
	].filter(e => !!e).join(` - `)

	const [loading, setLoading] = useState(false)
	const getInitialValues = (title, isKw, extraCase/*{COP, PUISSANCE, M2}*/) => {
		if (!isKw) {
			return []
		}
		const keyTitles = Object.keys(tkeys)
		return isKw.map(value => title.includes('Air/Eau') && value === tkeys.ETAS ? 126 : extraCase[keyTitles[value]])
	}
	const [cases, setCases] = useState(
		res.travaux
			.reduce((prev, title) => {
				const extraCase = extraCases.find(extraCase => extraCase.title === title) || {}
				return [
					...prev,
					{
						...(coutTravaux[title] || {}),
						title,
						scenarios: [],
						isKw: travauxTypes[title] || false,
						initialValues: getInitialValues(
							title,
							travauxTypes[title] || false,
							extraCase,
						),
						quantity: 1,
						...extraCase
					}
				]
			}, []))

	const updateCases = useCallback((lineIndex, caseType, checked) => {
		const allCases = Object.entries(responses)
			.filter(([key]) => key.includes('SCENARIO'))
			.map(([key, value]) => value)

		const { scenarios } = cases[lineIndex]
		let newScenarios = [...scenarios]
		if (checked) {
			if (scenarios.length === allCases.length - 1) {
				newScenarios = [responses.ALL]
			} else {
				newScenarios = [...scenarios, caseType]
			}
		} else {
			if (scenarios.includes(responses.ALL)) {
				newScenarios = [...allCases].filter(value => value !== caseType)
			} else {
				newScenarios = scenarios.filter(value => value !== caseType)
			}
		}
		setCases(cases.map((elem, index) =>
			index === lineIndex
				? { ...elem, scenarios: newScenarios }
				: elem))
	}, [cases])

	const updateCaseQuantity = useCallback((lineIndex, plus = false) => {
		setCases(cases.map((elem, index) =>
			index === lineIndex
				? { ...elem, quantity: plus ? (elem.quantity + 1) : (elem.quantity - 1) }
				: elem))
	}, [cases])

	const removeETAS = (str) => str.replace(/ \(ETAS ≥ \d+%\)/g, "")
	const casesReadyToSend = useMemo(() => /*isBao ? [...extraCases, ...cases] : */cases.map(({ title, ...rest }) => ({
		...rest,
		title: removeETAS(title)
	})), [cases, extraCases, isBao])

	const convertToPDF = useCallback(async () => {
		const downloadFile = async (fileURL, filename) => {
			return new Promise((resolve, reject) => {
				const link = document.createElement('a');
				link.href = fileURL;
				link.download = filename;
				document.body.appendChild(link);

				link.addEventListener('click', () => {
					document.body.removeChild(link);
					resolve(true);
				}, { once: true });

				link.click();
			});
		};

		let {
			iAuditorPath,
			jsonSynthese,
			rapportBAOPath,
			isBao
		} = res
		jsonSynthese = res.jsonSynthese ?
			{
				...res.jsonSynthese,
				cases: casesReadyToSend
			} : fakeForm
		setLoading(true)
		try {
			const response = await fetchApi('converter/getPdfFromForm', {
				body: {
					jsonSynthese,
					iAuditorPath,
					rapportBAOPath,
					isBao
				},
				responseType: 'blob',
			})
			const fileReceived = new Blob([response], { type: 'application/pdf' });
			const fileURL = URL.createObjectURL(fileReceived);
			await downloadFile(
				fileURL,
				((iAuditorPath ? 'Rapport_D_Audit' : 'Synthèse')
					+ '_'
					+ (jsonSynthese[isBao ? 'nom_etude' : 'beneficiaire'] + '_' + jsonSynthese.date_visite))
					.replace(/ /g, '_')
					.replace(/-/g, '_')
				+ '.pdf')
			setLoading(false)
			toast.success('Le téléchargement de la synthèse a été effectué.')
			onFileDownloaded()
		} catch (e) {
			setLoading(false)
			console.error(e)
			toast.error(e.message)
		}

	}, [casesReadyToSend, fetchApi, onFileDownloaded, res])
	const removeLine = useCallback((lineIndex) =>
		setCases(cases.filter((elem, index) => index !== lineIndex)),
		[cases])
	const duplicateLine = useCallback((lineIndex) =>
		setCases([
			...cases.slice(0, lineIndex + 1),
			{ ...cases[lineIndex], scenarios: [] },
			...cases.slice(lineIndex + 1),
		]),
		[cases])

	const updateKw = useCallback((values, lineIndex) => setCases(
		cases => cases.map(
			(actualCase, index) =>
				index === lineIndex ? {
					...actualCase,
					infos: getPuissanceAndCop(values)
				} : actualCase
		)), [setCases])

	return (
		<div>
			<table className={classes.casesTable}>
				<thead>
					<tr>
						<th>Travaux</th>
						<th>Sc1</th>
						<th>Sc2</th>
						<th>Sc3</th>
						{isBao && <th>Actions</th>}
					</tr>
				</thead>
				<tbody>
					{cases.map(({ title, scenarios, isKw, initialValues, quantity }, key) => <tr key={key}>
						<TitleCase
							initialValues={initialValues}
							isKw={isKw}
							title={title}
							updateKw={updateKw}
							lineIndex={key}
							quantity={quantity}
							updateQuantity={(plus) => updateCaseQuantity(key, plus)}
							isBao={isBao}
						/>
						<Case checked={
							scenarios.includes(responses.SCENARIO_1)
							|| scenarios.includes(responses.ALL)
						}
							onUpdate={
								(checked) =>
									updateCases(key, responses.SCENARIO_1, checked)
							}
						/>
						<Case checked={
							scenarios.includes(responses.SCENARIO_2)
							|| scenarios.includes(responses.ALL)
						}
							onUpdate={
								(checked) =>
									updateCases(key, responses.SCENARIO_2, checked)
							}
						/>
						<Case checked={
							scenarios.includes(responses.SCENARIO_3)
							|| scenarios.includes(responses.ALL)
						}
							onUpdate={
								(checked) =>
									updateCases(key, responses.SCENARIO_3, checked)
							}
						/>
						{isBao && <ActionCase line={key} duplicate={duplicateLine} remove={removeLine} />}
					</tr>)}
				</tbody>
			</table>
			<div>
				<Button className={classes.mt1} onClick={convertToPDF} loading={loading} >
					Convertir en PDF
				</Button>
			</div>

		</div>
	);
};

export default Cases;