import Home from "./views/home";
import SignIn from "./views/signIn";
import { Routes, BrowserRouter, Route, Navigate } from 'react-router-dom';
import { isAuthenticated } from "./utils/Auth"
import "./App.scss";
import React, { useContext, useMemo } from "react";
import { ToastContainer } from "react-toastify";
import { Provider, Store } from "store/store";
import UserCreate from "views/homeCompta/UserCreate";
import classNames from "classnames";
import classes from 'views/home/Home.module.scss'
import Header from "views/homeCompta/Header";
import UserPage from "views/homeCompta/UserPage";
import UploadCsv from "views/homeCompta/UploadCsv";
import Project from "views/homeCompta/Project";
import Collaborators from "views/homeCompta/Users";
import AuditorStats from "views/homeCompta/AuditorStats";
import InstallatorStats from "views/homeCompta/InstallatorStats";
import StatisticsMenu from "views/homeCompta/StatisticsMenu";
import ProjectCreate from "views/homeCompta/ProjectCreate";
import EngineerStats from "views/homeCompta/EngineerStats";
import AdminCreate from "views/admin/AdminCreate";
import AdminUserPage from "views/admin/AdminUserPage";
import MyUserPage from "views/admin/MyUserPage";
import MySyntheses from "views/admin/MySyntheses";
import UserSyntheses from "views/admin/UserSyntheses";
import AdminUsers from "views/admin/AdminUsers";
import AllSynthesesPage from "views/admin/AllSynthesesPage";
import ApiTableNavigationProjects from "views/homeCompta/ApiTableNavigationProjects";

const HomeRoute = () => {
	const { state: { user } } = useContext(Store)
	const isSyntheseUser = useMemo(() => !!user?.synthese, [user])

	return !!!user ? <SignIn /> :
		<div className={classNames(classes.principalContainer, isSyntheseUser && classes.synthese)}>
			{isSyntheseUser ? <Home /> : <ApiTableNavigationProjects />}
		</div>

}

const RouteByType = ({ children, type, isAdminRoute, isUserRoute }) => {
	const { state: { user } } = useContext(Store)
	const isSyntheseUser = useMemo(() => !!user?.synthese, [user])
	if (!isAuthenticated()) {
		return <Navigate to="/signIn" />
	}

	if ((isAdminRoute && !!!user?.admin)
		|| (
			(isSyntheseUser && type === 'compta')
			|| (!isSyntheseUser && type === 'synthese')
		)) {
		return <Navigate to="/" />
	}

	return <div className={classNames(classes.principalContainer, type === 'synthese' && classes.synthese)}>
		{children}
	</div>
}

const Router = () => {
	return <BrowserRouter>
		<div className={classNames(classes.relative, classes.fullWidth, classes.taCenter)}>
			<Header />
			<Routes>
				<Route
					path='/signIn'
					element={<SignIn />}
				/>
				<Route
					element={
						<RouteByType isAdminRoute>
							<AdminCreate />
						</RouteByType>
					}
					path="/admin/create"
				/>
				<Route
					element={
						<RouteByType isAdminRoute>
							<AdminUserPage />
						</RouteByType>
					}
					path="/admin/user/:userId"
				/>
				<Route
					element={
						<RouteByType isAdminRoute>
							<AdminUsers />
						</RouteByType>
					}
					path="/admin/users"
				/>
				<Route
					element={
						<RouteByType>
							<MyUserPage />
						</RouteByType>
					}
					path="/settings"
				/>
				<Route
					element={
						<RouteByType isAdminRoute type={'synthese'}>
							<UserSyntheses />
						</RouteByType>
					}
					path="/syntheses/:userId"
				/>
				<Route
					element={
						<RouteByType isUserRoute type={'synthese'}>
							<MySyntheses />
						</RouteByType>
					}
					path="/syntheses"
				/>
				<Route
					element={
						<RouteByType isAdminRoute type={'synthese'}>
							<AllSynthesesPage />
						</RouteByType>
					}
					path="/allSyntheses"
				/>
				<Route
					element={<HomeRoute />}
					path="/"
				/>
				<Route
					element={
						<RouteByType type={'compta'}>
							<UploadCsv />
						</RouteByType>
					}
					path="/uploadCsv"
				/>
				<Route
					element={
						<RouteByType type={'compta'}>
							<ApiTableNavigationProjects />
						</RouteByType>
					}
					path="/audits"
				/>
				<Route
					element={
						<RouteByType type={'compta'}>
							<ApiTableNavigationProjects />
						</RouteByType>
					}
					path="/projects"
				/>
				<Route
					element={
						<RouteByType type={'compta'}>
							<ApiTableNavigationProjects />
						</RouteByType>
					}
					path="/doublons/:type"
				/>
				<Route
					element={
						<RouteByType type={'compta'}>
							<Project />
						</RouteByType>
					}
					path="/project/:id"
				/>
				<Route
					element={
						<RouteByType type={'compta'}>
							<ProjectCreate />
						</RouteByType>
					}
					path="/project/create"
				/>
				<Route
					element={
						<RouteByType type={'compta'}>
							<UserCreate />
						</RouteByType>
					}
					path="/user/create"
				/>
				<Route
					element={
						<RouteByType type={'compta'}>
							<UserPage />
						</RouteByType>
					}
					path="/user/:userId"
				/>
				<Route
					element={
						<RouteByType type={'compta'}>
							<Collaborators />
						</RouteByType>
					}
					path="/users"
				/>
				<Route
					element={
						<RouteByType type={'compta'}>
							<AuditorStats />
						</RouteByType>
					}
					path="/statistics/auditor"
				/>
				<Route
					element={
						<RouteByType type={'compta'}>
							<InstallatorStats />
						</RouteByType>
					}
					path="/statistics/installator"
				/>
				<Route
					element={
						<RouteByType type={'compta'}>
							<EngineerStats />
						</RouteByType>
					}
					path="/statistics/engineer"
				/>
				<Route
					element={
						<RouteByType type={'compta'}>
							<StatisticsMenu />
						</RouteByType>
					}
					path="/statistics"
				/>
			</Routes>
		</div>
	</BrowserRouter>
}

const App = () => {
	return (
		<Provider>
			<ToastContainer position="bottom-left" />
			<Router />
		</Provider>
	);
}

export default App;