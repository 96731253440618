import FormComponent from 'components/Form/Form';
import toast from 'components/toast';
import React, { useCallback, useMemo, useState } from 'react';
import classes from 'styles/classes.module.scss'
import { useApi } from 'hooks/useApi';
import { useStoreUtils } from 'hooks/useStoreUtils';

const MyUserPage = () => {
	const [loading, setLoading] = useState(false)
	const { updateMe } = useApi()
	const { state: { user } } = useStoreUtils()

	const updateUser = useCallback(async (values) => {
		setLoading(true)
		try {
			await updateMe(values)
			toast.success('Info(s) actualisé(s).')
			return setLoading(false)
		} catch (error) {
			setLoading(false)
			return toast.error('Erreur durant la modification de l\'utilisateur : ' + error.message)
		}
	}, [updateMe])

	const inputs = useMemo(() => [
		{
			type: 'text',
			name: 'firstname',
			label: 'Prénom',
			initialValue: user?.firstname,
			value: user?.firstname,
		},
		{
			type: 'text',
			name: 'lastname',
			label: 'Nom',
			initialValue: user?.lastname,
			value: user?.lastname
		},
	], [user])

	if (!user) {
		return null
	}

	return (
		<div className={classes.marginAuto}>
			<div>
				<h3>Mes informations</h3>
				<FormComponent
					loading={loading}
					inputs={inputs}
					sendForm={updateUser}
					centered={true}
					responsive={false}
					segment={false}
				/>
			</div>
		</div>
	);
};

export default MyUserPage;