import { getHeaders } from "helpers"
import { useCallback, useContext, useMemo } from "react"
import { Store } from "store/store"
import { useDisconnect } from "./useDisconnect"
import { saveToken } from "utils/Auth"
import { jwtDecode } from "jwt-decode"

export const useApi = () => {
	const { logout } = useDisconnect()
	const { state: { user }, dispatch } = useContext(Store)
	const isComptaUser = useMemo(() => !!!user?.synthese, [user])
	const isUser = useMemo(() => !!user, [user])

	const fetchApi = useCallback((route, params = null, adminRoute = false, queryParams = '') => {
		const apiUrl = `${process.env.REACT_APP_API_URL}${isUser && isComptaUser && !adminRoute ? '/compta/' : '/'}${route}`;
		const fetchUrl = !!queryParams ? `${apiUrl}?${queryParams}` : apiUrl;
		return fetch(
			fetchUrl,
			params ? {
				method: 'POST',
				headers: getHeaders(params),
				...params,
				body: params.multiform ? params.body : JSON.stringify(params.body || {}),
			} : { headers: getHeaders({}) })
			.then(async res => {
				//ici si il nous passe un nouveau token on l'enregistre dans le local storage
				if (!res.ok) { throw res }
				return params?.responseType === 'blob' ? res.blob() : res.json()
			}).catch(async err => {
				let { message } = err
				if (err.json) {
					const jsonError = await err.json()
					if (jsonError.message) {
						message = jsonError.message
					}
					if (message === 'Disconnect') {
						return logout()
					}
					throw jsonError
				}
				throw err
			})
	}, [isComptaUser, isUser, logout])

	const sendCsvFile = useCallback((form) => fetchApi('uploadCsv', { body: form, multiform: true }), [fetchApi])
	const sendCsvFileAudits = useCallback((form) => fetchApi('uploadCsvAudits', { body: form, multiform: true }), [fetchApi])
	const createUser = useCallback((infos) => fetchApi('user', { body: infos }), [fetchApi])
	const getUser = useCallback((id) => fetchApi('user/' + id), [fetchApi])
	const updateUser = useCallback((id, infos) => fetchApi('user/' + id, { method: 'PUT', body: infos }), [fetchApi])
	const getUsers = useCallback(() => fetchApi('users'), [fetchApi])
	const deleteUser = useCallback((userId) => fetchApi('user/' + userId, { method: 'DELETE' }), [fetchApi])
	const getUserDeposits = useCallback((userId) => fetchApi('deposits/' + userId), [fetchApi])
	const createDeposit = useCallback((body) => fetchApi('deposit', { body }), [fetchApi])
	const updateDeposit = useCallback((depositId, body) => fetchApi('deposit/' + depositId, { body, method: 'PUT' }), [fetchApi])
	const deleteDeposit = useCallback((depositId) => fetchApi('deposit/' + depositId, { method: 'DELETE' }), [fetchApi])
	const createProject = useCallback((infos) => fetchApi('project', { body: infos }), [fetchApi])
	const deleteProject = useCallback((projectId) => fetchApi('project/' + projectId, { method: 'DELETE' }), [fetchApi])
	const deleteProjects = useCallback((projectIds) => fetchApi('projects', { method: 'DELETE', body: { projectIds } }), [fetchApi])
	const getProject = useCallback((id) => fetchApi('project/' + id), [fetchApi])
	const getProjects = useCallback((userId = null, userType, urlParams = '') => fetchApi(`projects${userId ? `/${userId}/${userType}` : ''}`, null, false, urlParams) , [fetchApi])
	const getNbProjects = useCallback((urlParams = {}) => fetchApi('projects/count', null, false, urlParams), [fetchApi])
	const updateProject = useCallback((id, infos) => fetchApi('project/' + id, { method: 'PUT', body: infos }), [fetchApi])
	const updateProjects = useCallback((ids, infos) => fetchApi('projects', { method: 'PUT', body: {ids, ...infos} }), [fetchApi])
	const getStatuses = useCallback(() => fetchApi('statuses'), [fetchApi])


	const createUserSynthese = useCallback((body) => fetchApi('users/user', { body }, true), [fetchApi])
	const getUserSynthese = useCallback((userId) => fetchApi('users/user/' + userId, null, true), [fetchApi])
	const updateUserSynthese = useCallback((userId, body) => fetchApi('users/user/' + userId, { body, method: 'PATCH' }, true), [fetchApi])
	const regenerateToken = useCallback(() =>
		fetchApi('users/regenerateToken', null, true)
			.then(token => {
				saveToken(token)
				const user = jwtDecode(token)
				dispatch({
					type: 'USER/UPDATE',
					payload: user
				})
			})
		, [dispatch, fetchApi])
	const updateMe = useCallback((body) => fetchApi('users/user', { body, method: 'PATCH' }, true)
		.then(regenerateToken), [fetchApi, regenerateToken])

	const getUserSyntheses = useCallback((userId) => fetchApi('syntheses/user/' + userId, null, true), [fetchApi])
	const getAllSyntheses = useCallback(() => fetchApi('syntheses', null, true), [fetchApi])
	const getAllUsers = useCallback(() => fetchApi('users', null, true), [fetchApi])
	const getNewReference = useCallback(() => fetchApi('syntheses/reference', null, true), [fetchApi])

	return {
		fetchApi,
		sendCsvFile,
		sendCsvFileAudits,
		createUser,
		getUser,
		updateUser,
		getUsers,
		deleteUser,
		getUserDeposits,
		createDeposit,
		updateDeposit,
		deleteDeposit,
		createProject,
		deleteProject,
		deleteProjects,
		getProject,
		getProjects,
		updateProject,
		updateProjects,
		getStatuses,
		createUserSynthese,
		getUserSynthese,
		updateUserSynthese,
		updateMe,
		regenerateToken,
		getUserSyntheses,
		getAllSyntheses,
		getAllUsers,
		getNbProjects,
		getNewReference
	}

}