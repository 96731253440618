import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';

const MyAutocomplete = ({
    options,
    onUpdate,
    defaultValue,
    label,
    width = 400
}) => {
    const [value, setValue] = useState(defaultValue || null);

    const handleSelection = (event, newValue) => {
        setValue(newValue);
        onUpdate(newValue);
    };

    useEffect(() => {
        setValue(defaultValue);

    }, [defaultValue])

    return <Autocomplete
        freeSolo
        disablePortal
        options={options}
        defaultValue={value ? options.find(({ id }) => id === value) || null : null}
        value={value}
        onChange={handleSelection}
        sx={{ width }}
        renderInput={(params) => <TextField {...params} label={label} />}
    />
};

export default MyAutocomplete;
