import { Form, Input, Label } from 'semantic-ui-react'
import FileInput from './FileInput'
import ImageInput from './ImageInput'
import InfoInput from './InfoInput'
import classes from './Form.module.scss'
import classNames from 'classnames'
import { useMemo } from 'react'
import { isNumber, getImageUrl, getImageUrlWithName } from 'helpers'

const TextInput = ({
	onKeyPress = () => { },
	type,
	name,
	onChange,
	value,
	initialValue,
	error,
	labelObject,
	styles = { container: '' },
	placeholder,
	required,
	silentRequired = false,
	toCheck = false,
	disabled = false,
	options = [],
	maxLength = null,
	fluid = false,
	legend = null,
	inline = true,
	responsive = true,
	style = {},
	labelStyle = {},
	centered = false,
	info = null,
	label,
	handleEffect,
	requiredLang,
	fileLang,
	fileErrorLang,
	fileUpdateLang,
	onBlur = null,
	forwardRef = null,
	children,
	onFocus = () => { },
	errorLogic,
	preview,
	formError = false,
	min = false
}) => {
	const isError = useMemo(() => formError && error && required, [error, formError, required])

	return (
		<Form.Field
			required={required}
			className={classNames(styles.container,
				classes.field,
				styles.container,
				responsive
					? centered
						? classes.horizontalCenteredContainer
						: classes.flex
					: '')}
			inline={inline}
		>
			{label ? (
				<label className={classes.conformLabel} style={labelStyle} htmlFor={name + (initialValue ? `-${initialValue}` : '')}>
					{label}
				</label>
			) : null}
			{type === 'image' ? (
				<>
					<ImageInput
						id={name + (initialValue ? `-${initialValue}` : '')}
						name={name}
						onChange={handleEffect}
						// onBlur={handleEffect}
						fileLang={value ? (typeof value === 'string' ? value
							: isNumber(value) ? fileUpdateLang
								: value.name) : fileLang}
						fileErrorLang={fileErrorLang}
					/>
					{value && preview ? (
						<img
							className={classes.previewImage}
							src={
								isNumber(value)
									? getImageUrl(value, true)
									: typeof value === 'string'
										? getImageUrlWithName(value, true)
										: URL.createObjectURL(value)
							}
							alt="object"
						/>
					) : null}
				</>
			) : type === 'file' ?
				<>
					<FileInput
						id={name + (initialValue ? `-${initialValue}` : '')}
						name={name}
						onChange={handleEffect}
						// onBlur={handleEffect}
						fileLang={value ? (typeof value === 'string' ? value
							: isNumber(value) ? fileUpdateLang
								: value.name) : fileLang}
						fileErrorLang={fileErrorLang}
					/>
					{value && preview ? (
						<div>
							<div>{typeof value === 'string' ? value : value.filename}</div>
							{/* {!!(typeof value === 'string' && [
                                'png',
                                'x-png',
                                'jpeg',
                                'jpg',
                                'JPG',
                                'gif',
                            ].includes(getFileExtension(value))) && <img
                                    className="preview-image"
                                    src={typeof value === 'string' ? `${apiUrl}/images/${value}` : URL.createObjectURL(value)}
                                    alt={"Fichier : " + value}
                                />} */}
						</div>
					) : null}
				</>
				: (
					<Input
						{...(forwardRef ? { ref: forwardRef } : {})}
						className={classNames(classes.hFit, classes.myInputIsChild, isError && classes.error)}
						onKeyPress={onKeyPress}
						id={name + (initialValue ? `-${initialValue}` : '')}
						name={name}
						type={type}
						value={value != null ? value : ''}
						placeholder={placeholder}
						onChange={handleEffect}
						onBlur={onBlur || handleEffect}
						fluid={fluid}
						style={style}
						disabled={disabled}
						onFocus={onFocus}
						{...(maxLength ? { maxLength } : {})}
						{...(min ? { min } : {})}
					/>
				)}
			{legend ? (
				<div>
					<p className={classes.legendInput}>{legend}</p>
				</div>
			) : null}
			{info ? <InfoInput info={info} /> : null}
			{children}
			{errorLogic || (required && error && !silentRequired) ? (
				<Label
					style={{
						display: 'inline-table',
						marginTop: 'auto',
						marginBottom: 'auto',
					}}
					pointing="left"
					color="red"
					basic
				>
					{errorLogic || requiredLang}
				</Label>
			) : null}
		</Form.Field>
	)
}

export default TextInput
