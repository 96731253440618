import FormComponent from 'components/Form/Form';
import toast from 'components/toast';
import { getDateFormatted } from 'dates';
import React, { useMemo, useState } from 'react';
import classes from 'styles/classes.module.scss'
import { useApi } from 'hooks/useApi';

const AddDeposit = ({
    userId,
    setDeposits = () => { }
}) => {
	const { createDeposit } = useApi()
    const [loading, setLoading] = useState(false)

    const inputs = useMemo(() => [
        {
            type: 'number',
            name: 'deposit',
            value: 0,
            required: true,
            silentRequired: true,
            label: 'Acompte € TTC',
        },
        {
            type: 'date',
            name: 'date',
            required: true,
            silentRequired: true,
            value: getDateFormatted(new Date()),//à voir
            label: 'Date du transfert',
        }
    ], [])

    const add = async (values) => {
        setLoading(true)
        try {
            const newDeposit = {
                ...values,
                user: userId
            }
            const depositSaved = await createDeposit(newDeposit)
            setDeposits(deposits => [...deposits, { ...depositSaved, ...newDeposit }])
            toast.success('Acompte créé')
        } catch (error) {
            toast.error('Erreur durant la création de l\'acompte:' + error.message)
            console.log('error', error)
            return
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className={classes.mt2}>
            <h3>Ajouter un acompte</h3>
            <FormComponent
                loading={loading}
                inputs={inputs}
                sendForm={add}
                centered={true}
                responsive={false}
                segment={false}
                messages={{ submit: 'Ajouter un acompte' }}
            />
        </div>
    );
};

export default AddDeposit;