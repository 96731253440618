import React, { useMemo } from 'react';
import { collaboratorTypes } from './collaborator.constant';
import { useProjects } from 'hooks/useProjects';

const TotalPrice = ({
	field,
	user,
	deposit = 0
}) => {
	const { projects } = useProjects()
	const caHT = useMemo(() =>
		projects.reduce((prev, actual) => prev + parseFloat(actual[field] || 0), 0),
		[projects, field])
	const caTTC = useMemo(() =>
		projects.reduce((prev, actual) =>
			prev + parseFloat(actual[field] || 0), 0) * (1 + parseFloat(user?.tva || 0)),
		[projects, field, user])

	const totalTTC = useMemo(() => caTTC - deposit, [caTTC, deposit])
	return (
		<div>
			<div>
				<h3>CA HT</h3>
				{caHT}
			</div>
			<div>
				<h3>CA TTC</h3>
				{caTTC}
			</div>
			{[collaboratorTypes.AMO, collaboratorTypes.INGENIEUR].includes(user?.type) && <div>
				<h3>Acompte TTC</h3>
				{deposit}
			</div>}
			{[collaboratorTypes.AMO, collaboratorTypes.INGENIEUR].includes(user?.type) && <div>
				<h3>Total TTC</h3>
				{totalTTC}
			</div>}
		</div>
	);
};

export default TotalPrice;