import { isEmpty } from "lodash"

export const TOKEN_KEY = "token"

export const isAuthenticated = () => {
	return !isEmpty(localStorage.getItem(TOKEN_KEY))
}

export const saveToken = token => {
	localStorage.setItem(TOKEN_KEY, token)
}

export const removeToken = () => {
	localStorage.removeItem(TOKEN_KEY)
}

export const getToken = () => {
	return localStorage.getItem(TOKEN_KEY)
}