import Button from 'components/Button';
import MyTable from 'components/MyTable/MyTable';
import { columnTypes } from 'components/MyTable/MyTable.constant';
import toast from 'components/toast';
import { timeDateFormat } from 'dates';
import React, { useCallback, useMemo } from 'react';
import classes from 'styles/classes.module.scss'
import CheckBoxInput from './CheckBoxInput';
import ProjectInput from './ProjectInput';
import { useApi } from 'hooks/useApi';


const ProjectsTable = ({
	projects,
	nbProjects = null,
	title = 'Projets',
	setProjects = () => { },
	actionDisable = false,
	checkBoxFunc = false,
	isAudit = false
}) => {
	const { deleteProject, updateProject } = useApi()
	const removeProject = useCallback(async (projectId) => {
		try {
			await deleteProject(projectId)
			setProjects(projects => projects.filter(({ id }) => id !== projectId))
			toast.success('Projet supprimé.')
		} catch (error) {
			console.log('error', error)
			toast.success('Erreur durant la suppression du projet: ' + error.message)
		}
	}, [deleteProject, setProjects])

	const updateProjectFunc = useCallback(async (projectId, valuesUpdated) => {
		try {
			await updateProject(projectId, valuesUpdated)
			setProjects(projects => projects.map(p => p.id === projectId ? { ...p, ...valuesUpdated } : p))
			toast.success('Projet actualisé.')
		} catch (error) {
			console.log('error', error)
			toast.success('Erreur durant l\'actualisation du projet: ' + error.message)
		}
	}, [setProjects, updateProject])

	const columns = useMemo(() => [
		!!checkBoxFunc && { title: '', render: project => <CheckBoxInput checked={project.checked} onUpdate={() => checkBoxFunc(project)} /> },
		{ field: 'id', title: 'ID', render: ({ id }) => <Button external link={`/project/${id}`}>{id}</Button> },
		{ field: 'ticketKey', title: 'Clé ticket', type: columnTypes.TEXT },
		{ field: 'auditType', title: 'Type d\'audit', type: columnTypes.TEXT },
		{ field: 'cp', title: 'Code postal', render: ({ cp }) => cp },
		{ field: 'name', title: 'Nom du client', type: columnTypes.TEXT, render: ({ name }) => <div className='highlight'> {name} </div> },
		{ field: 'status', title: 'Statut', type: columnTypes.TEXT, render: ({ statusObj }) => statusObj?.status },
		!!isAudit && { field: 'auditType', title: 'Type d\'audit', type: columnTypes.TEXT, render: ({ auditType }) => auditType },
		{ field: 'createdDate', title: 'Date de création', type: columnTypes.DATE, render: ({ createdDate }) => timeDateFormat(createdDate) },
		{ field: 'auditDate', title: 'Date d\'audit', type: columnTypes.DATE, render: ({ auditDate }) => timeDateFormat(auditDate) },
		{ field: 'createdAt', title: 'Date d\'importation', type: columnTypes.DATE, render: ({ createdAt }) => timeDateFormat(createdAt) },
		{ field: 'auditorDefault', title: 'Auditeur', type: columnTypes.TEXT, render: ({ auditorUser }) => auditorUser?.name },
		{ field: 'regieDefault', title: 'Régie', type: columnTypes.TEXT, render: ({ regieUser }) => regieUser?.name },
		{ field: 'engineerDefault', title: 'Ingénieur', type: columnTypes.TEXT, render: ({ engineerUser }) => engineerUser?.name },
		{
			field: 'priceAuditor', type: columnTypes.NUMERIC, title: 'Prix auditeur HT', render: ({ priceAuditor, id }) =>
				<ProjectInput
					name="priceAuditor"
					value={priceAuditor}
					onEnter={(values) => updateProjectFunc(id, values)}
				/>
		},
		{
			field: 'priceInstallator', type: columnTypes.NUMERIC, title: 'Prix installateur HT', render: ({ priceInstallator, id }) =>
				<ProjectInput
					name="priceInstallator"
					value={priceInstallator}
					onEnter={(values) => updateProjectFunc(id, values)}
				/>
		},
		{
			field: 'priceEngineer', type: columnTypes.NUMERIC, title: 'Prix ingénieur HT', render: ({ priceEngineer, id }) =>
				<ProjectInput
					name="priceEngineer"
					value={priceEngineer}
					onEnter={(values) => updateProjectFunc(id, values)}
				/>
		},
		{
			field: 'kumac', type: columnTypes.NUMERIC, title: 'Kumac', render: ({ kumac, id }) =>
				<ProjectInput
					name="kumac"
					value={kumac}
					onEnter={(values) => updateProjectFunc(id, values)}
				/>
		},
		!actionDisable && {
			title: 'Actions', render: ({ id }) => <div>
				<Button type='danger' onClick={(e) => {
					e.stopPropagation()
					removeProject(id)
				}}>Supprimer</Button>
			</div>
		},
	].filter(e => !!e), [updateProjectFunc, isAudit, removeProject, actionDisable, checkBoxFunc])
	return (
		<div style={{ maxWidth: "100%" }} className={classes.marginAuto}>
			<MyTable
				counter={true}
				nbTotalRow={nbProjects}
				columns={columns}
				data={projects}
				title={title}
				options={{
					paging: true,
					pageSize: 50
				}}
			// onRowClick={(event, rowData) => navigate(`/project/${rowData.id}`)}
			/>
		</div>
	);
};

export default ProjectsTable;
