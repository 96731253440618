import { useEffect, useState } from 'react'
import { useApi } from './useApi'

export const useStatuses = () => {
	const {getStatuses} = useApi()
    const [statuses, setStatuses] = useState([])

    useEffect(() => {
        getStatuses()
            .then(setStatuses)
    }, [])

    return {
        statuses,
    }
}
