import FormComponent from 'components/Form/Form';
import toast from 'components/toast';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import classes from 'styles/classes.module.scss'
import { useApi } from 'hooks/useApi';
import Button from 'components/Button';
import { getUserSyntheseName } from 'helpers';

const AdminUserPage = () => {
	const [loading, setLoading] = useState(false)
	const { getUserSynthese, updateUserSynthese } = useApi()
	const { userId } = useParams()
	const [user, setUser] = useState()

	useEffect(() => {
		getUserSynthese(userId)
			.then(setUser)
	}, [userId, getUserSynthese])

	const updateUser = useCallback(async (values) => {
		setLoading(true)
		try {
			await updateUserSynthese(userId, values)
			toast.success('Info(s) actualisé(s).')
			return setLoading(false)
		} catch (error) {
			setLoading(false)
			return toast.error('Erreur durant la modification de l\'utilisateur : ' + error.message)
		}
	}, [userId, updateUserSynthese])

	const inputs = useMemo(() => [
		{
			type: 'password',
			name: 'password',
			label: 'Mot de passe',
			required: true,
			silentRequired: true,
		},
	], [])

	const blockUser = useCallback(async () => {
		try {
			await updateUser({ blocked: !!!user.blocked })
			setUser({
				...user,
				blocked: !!!user.blocked
			})
		} catch (error) {
			console.log('error', error)
		}

	}, [updateUser, user])

	const setAdminUser = useCallback(async () => {
		try {
			await updateUser({ admin: !!!user.admin })
			setUser({
				...user,
				admin: !!!user.admin
			})
		} catch (error) {
			console.log('error', error)
		}

	}, [updateUser, user])

	if (!user) {
		return null
	}

	return (
		<div className={classes.marginAuto}>
			<h2>Page de {getUserSyntheseName(user)}{!!user.admin ? ' - ADMIN' : ''}</h2>
			<p>{user?.email}</p>
			<Button type='warning' onClick={blockUser}>{user.blocked ? 'Débloquer' : 'Bloquer'} l'utilisateur</Button>
			<Button type='danger' onClick={setAdminUser}>{user.admin ? 'Rendre utilisateur' : 'Rendre admin'}</Button>
			<div>
				<h3>Modification du mot de passe</h3>
				<FormComponent
					loading={loading}
					inputs={inputs}
					sendForm={updateUser}
					centered={true}
					responsive={false}
					segment={false}
				/>
			</div>
		</div>
	);
};

export default AdminUserPage;