import classNames from 'classnames';
import React, { useMemo } from 'react';
import classes from 'styles/classes.module.scss'
import { collaboratorTypes } from './collaborator.constant';

const StatsTotal = ({ users, userType }) => {
    const caTotalHT = useMemo(() => users.reduce((prev, actual) =>
        prev + actual.caHT, 0), [users])
    const caTotalTTC = useMemo(() => users.reduce((prev, actual) =>
        prev + actual.caTTC, 0), [users])
    const totalVisites = useMemo(() => users.reduce((prev, actual) =>
        prev + actual.RDVs, 0), [users])
    const totalAudits = useMemo(() => users.reduce((prev, actual) =>
        prev + actual.Audits, 0), [users])
    const totalDeposit = useMemo(() => users.reduce((prev, actual) =>
        prev + actual.deposit, 0), [users])
    const finalTTC = useMemo(() => caTotalTTC - totalDeposit, [caTotalTTC, totalDeposit])

    return (
        <div className={classes.taCenter}>
            <div className={classNames(classes.inlineFlex, classes.flexWrap)}>
                <div>
                    <div>CA TOTAL (HT)</div>
                    <div>{caTotalHT}</div>
                </div>
                <div>
                    <div>CA TOTAL (TTC)</div>
                    <div>{caTotalTTC}</div>
                </div>
                {[collaboratorTypes.AMO, collaboratorTypes.INGENIEUR].includes(userType) &&
                    <div>
                        <div>Acompte (TTC)</div>
                        <div>{totalDeposit}</div>
                    </div>}
                <div>
                    <div>FINAL (TTC)</div>
                    <div>{finalTTC}</div>
                </div>
                <div>
                    <div>Total visites</div>
                    <div>{totalVisites}</div>
                </div>
                {userType !== collaboratorTypes.AMO && <div>
                    <div>Total audits</div>
                    <div>{totalAudits}</div>
                </div>}

            </div>
        </div>

    );
};

export default StatsTotal;