import Button from 'components/Button';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import classes from 'styles/classes.module.scss'

const StatisticsMenu = () => {
    const navigate = useNavigate()
    return (
        <div className={classes.marginAuto}>
            <Button onClick={() => navigate('/statistics/auditor')}>Auditeurs</Button>
            <Button onClick={() => navigate('/statistics/installator')}>Installateurs</Button>
            <Button onClick={() => navigate('/statistics/engineer')}>Ingénieurs</Button>
        </div>
    );
};

export default StatisticsMenu;