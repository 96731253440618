import React from 'react';
import { collaboratorTypes } from './collaborator.constant';
import Statistics from './Statistics';

const AuditorStats = () => {
    return (
        <Statistics userType={collaboratorTypes.AMO} />
    );
};

export default AuditorStats;