import { useEffect, useState } from 'react';

function useTextHighlighter() {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let timeoutId;

    function handleMouseUp() {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        const selection = window.getSelection();
        const selectedText = selection.toString();

        if (selectedText) {
          const range = selection.getRangeAt(0);
          const elements = document.querySelectorAll('.highlight');
          setCount(Array.from(elements || []).filter((element) =>
            range.intersectsNode(element)
          ).length)
        } else {
          setCount(0);
        }
      }, 100); // délai de 100ms
    }

    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('selectionchange', handleMouseUp);

    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('selectionchange', handleMouseUp);
      clearTimeout(timeoutId);
    };
  }, []);

  return count;
}

export default useTextHighlighter;
