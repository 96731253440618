import { useApi } from 'hooks/useApi';
import { useStoreUtils } from 'hooks/useStoreUtils';
import React, { useEffect, useState } from 'react';
import SynthesesTable from 'views/homeCompta/SynthesesTable';

const AllSynthesesPage = () => {
	const { getAllSyntheses } = useApi()
	const { state: { user } } = useStoreUtils()
	const [syntheses, setSyntheses] = useState([])

	useEffect(() => {
		getAllSyntheses(user.id)
			.then(setSyntheses)
			.catch(e => console.log(e))
	}, [getAllSyntheses, user])

	return (
		<SynthesesTable syntheses={syntheses} title='Toutes les synthèses' />
	);
};

export default AllSynthesesPage;