import React, { useCallback, useMemo, useState } from "react"
import "../../App.scss";
import FilesToSend from "./FilesToSend"
import Button from "components/Button"
import FormToSend from "./FormToSend"
import classes from './Home.module.scss'
import classNames from "classnames"
import PreviewPrimeAndCosts from "./PreviewPrimesAndCosts/PreviewPrimeAndCosts"
import CasesSas from "./CasesSas";
import RestitutionChoicePage from "./RestitutionChoicePage";
import { fakeJsonSynthese } from './fakeJsonSynthese'
import { useApi } from "hooks/useApi";

function Home() {
	const steps = useMemo(() => ({
		UPLOAD: 0,
		ASK: 1,
		FORM: 2,
		CASES: 3,
	}), [])
	const [step, setStep] = useState(0)
	const [res, setRes] = useState()

	const reset = () => {
		setStep(steps.UPLOAD)
		setRes()
	}

	const filesSent = useCallback((res, reference) => {
		setRes(() => ({
			...res,
			jsonSynthese: {
				...res.jsonSynthese,
				reference
			}
		}))
		setStep(steps.ASK)
	}, [steps])
	const formSent = useCallback((res, step = steps.CASES) => {
		setRes(r => ({ ...r, jsonSynthese: { ...r.jsonSynthese, ...res } }))
		setStep(step)
	}, [steps])
	const jsonSynthese = useMemo(() => res && res.jsonSynthese, [res])

	const updateJsonSynthese = useCallback((updatedValues) => setRes(res => ({
		...res,
		jsonSynthese: {
			...res.jsonSynthese,
			...updatedValues
		}
	})), [setRes])

	const devAction = useCallback(() => {
		setRes(res => ({
			...res,
			jsonSynthese: {
				...fakeJsonSynthese,
				...res.jsonSynthese,
			}
		}))
		setStep(steps.CASES)
	}, [])

	return (
		<div className={classNames(classes.relative, classes.fullWidth)} >
			<div className={classes.headerSynthese}>

				<Button
					type="warning"
					onClick={reset}
				>
					Relancer une synthèse
				</Button>
			</div>
			{jsonSynthese && <PreviewPrimeAndCosts
				updateJsonSynthese={updateJsonSynthese}
				jsonSynthese={jsonSynthese}
			/>}
			{
				step === steps.UPLOAD ? <FilesToSend
					onSent={filesSent}
				/> : step === steps.ASK ? <RestitutionChoicePage
					devAction={devAction}
					onSent={res => formSent(res, steps.FORM)}
					res={res}
				/> : step === steps.FORM ? <FormToSend
					onSent={res => formSent(res, steps.CASES)}
					res={res}
				/> : step === steps.CASES ? <CasesSas
					res={res}
					onFileDownloaded={reset}
				/> : null
			}
		</div>
	)
}

export default Home

