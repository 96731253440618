import FormComponent from 'components/Form/Form';
import toast from 'components/toast';
import { getOnlyUpdatedValues } from 'helpers';
import { isEmpty } from 'lodash';
import React, { useMemo, useState } from 'react';
import { collaboratorTypes } from './collaborator.constant';
import { tvaTypes } from './tva.constant';
import { useApi } from 'hooks/useApi';

const UpdateUser = ({
    user,
    setUser,
    userTypeTitle
}) => {
	const { updateUser } = useApi()
    const [loading, setLoading] = useState(false)

    const inputs = useMemo(() => [
        {
            type: 'select',
            name: 'type',
            label: 'Type de collaborateur',
            required: true,
            silentRequired: true,
            value: String(user?.type),
            options: Object
                .keys(collaboratorTypes)
                .map((collaboratorType, index) => ({
                    key: String(index),
                    value: String(index),
                    text: collaboratorType
                })),
        },
        {
            type: 'text',
            name: 'name',
            value: user?.name,
            label: 'Nom',
        },
        {
            type: 'number',
            name: 'projectPrice',
            value: user?.projectPrice,
            label: 'Prix projet HT',
        },
        user?.type === collaboratorTypes.INSTALLATEUR &&  {
            type: 'number',
            name: 'auditPrice',
            value: user?.auditPrice,
            label: 'Prix audit HT',
        },
        {
            type: 'select',
            name: 'tva',
            label: 'Est-il assujetti à la TVA ?',
            required: true,
            silentRequired: true,
            value: String(user?.tva === 0 ? 0 : 1),
            options: Object
                .keys(tvaTypes)
                .map((tvaType, index) => ({
                    key: String(index),
                    value: String(index),
                    text: tvaType
                })),
        },
        user?.type === collaboratorTypes.INSTALLATEUR && {
            type: 'text',
            name: 'code',
            value: user?.code,
            label: 'Code',
        },
    ].filter(e => !!e), [user])

    const update = async (values) => {
        setLoading(true)
        if (Number(values.tva) === tvaTypes.OUI) {
            values.tva = 0.2
        }
        try {
            const updatedValues = getOnlyUpdatedValues(user, values, true)
            if (isEmpty(updatedValues)) {
                toast.error('Aucune info n\'a changé.')
                return
            }
            await updateUser(user?.id, updatedValues)
            setUser(user => ({ ...user, ...updatedValues }))
            toast.success('Infos actualisées')
        } catch (error) {
            toast.error('Erreur durant l\'actualisation des infos :' + error.message)
            console.log('error', error)
            return
        } finally {
            setLoading(false)
        }
    }

    return (
        <div>
            <h3>Informations de l'{userTypeTitle}</h3>
            <FormComponent
                loading={loading}
                inputs={inputs}
                sendForm={update}
                centered={true}
                responsive={false}
                segment={false}
                messages={{ submit: 'Actualiser les infos' }}
            />
        </div>
    );
};

export default UpdateUser;