import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './DropDownMenu.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';

export default function DropDownMenu({ defaultOption, options: optionsFromParams, defaultStateText }) {
const options = useMemo(() => {
	if (defaultStateText) {
		return [defaultStateText, ...optionsFromParams]
	}
	return optionsFromParams
}, [optionsFromParams, defaultStateText])
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState('status');
  const open = useMemo(() => Boolean(anchorEl), [anchorEl])
	const { search, pathname } = useLocation()
	const searchParams = useMemo(() => new URLSearchParams(search), [search])
	const navigate = useNavigate()

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const onOptionSelected = useCallback((status) => {
	if (defaultStateText === status) {
		searchParams.delete('status')
	}else{
		searchParams.set('status', encodeURIComponent(status))
	}
		navigate(`?${searchParams.toString()}`, { replace: true })
	}, [navigate, searchParams, pathname])

  const handleClose = useCallback((option) => {
    setAnchorEl(null)
    setSelectedOption(option)
    if (onOptionSelected) {
      onOptionSelected(option)
    }
  }, [options, onOptionSelected])

  useEffect(() => {
    setSelectedOption(defaultOption)
  }, [pathname])

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className="dropDownButton"
        endIcon={<ArrowDropDownIcon />}
      >
        {selectedOption}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={() => handleClose(selectedOption)}
        className="menuList"
      >
        {options.map((option, index) => (
          <MenuItem key={index} onClick={() => handleClose(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
