import React, { useState } from 'react';
import TablePage2_1 from './TablePage2_1';
import TablePage2_2 from './TablePage2_2';
import TablePage2_3 from './TablePage2_3';
import classes from './Preview.module.scss';
import Button from 'components/Button';

const PreviewPrimeAndCosts = ({
	jsonSynthese = {},
	updateJsonSynthese = () => { }
}) => {
	const [visible, setVisible] = useState(true)
	return (
		<div style={{ marginBottom: 5 }} className={classes.container} >
			<Button onClick={() => setVisible(visible => !visible)}>
				{visible ? 'Cacher' : 'Voir'} l'élément
			</Button>
			{visible && <> <TablePage2_1 {...jsonSynthese} updateJsonSynthese={updateJsonSynthese} />
				<TablePage2_2 {...jsonSynthese} updateJsonSynthese={updateJsonSynthese} />
				{/* <TablePage2_3 {...jsonSynthese}updateJsonSynthese={updateJsonSynthese} /> */}
			</>
			}
		</div>
	);
};

export default PreviewPrimeAndCosts;