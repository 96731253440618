import React, { Fragment } from 'react';
import JsonSyntheseSelect from './JsonSyntheseSelect';
import { energyTypes } from 'views/homeCompta/energy.constant';
import JsonSyntheseInput from './JsonSyntheseInput';
import { numberToString } from 'helpers';

const TablePage2_1 = ({
	updateJsonSynthese = () => { },
	...jsonSynthese
}) => {
	return (<div>
		<h2 className="align-center underline color-blue">CALCUL DES PRIMES ET DU COÛT DES TRAVAUX</h2>
		<div className="ta-center">
			<table className="m2-auto">
				<thead>
					<tr>
						<th className="ta-center">
						</th>
						<th colSpan="2" className="font-medium ta-center">
							Situation initiale
						</th>
						<th colSpan="2" className="font-medium ta-center">
							Scénario 1
						</th>
						<th colSpan="2" className="font-medium ta-center">
							Scénario 2
						</th>
						<th colSpan="2" className="font-medium ta-center">
							Scénario 3
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td className="font-medium align-left">Usage</td>
						<td className="font-medium align-left">Energie</td>
						<td className="font-medium align-left">Consommation <span style={{ fontSize: 8 }}>[KWhEP/an]</span></td>
						<td className="font-medium align-left">Energie</td>
						<td className="font-medium align-left">Consommation <span style={{ fontSize: 8 }}>[KWhEP/an]</span></td>
						<td className="font-medium align-left">Energie</td>
						<td className="font-medium align-left">Consommation <span style={{ fontSize: 8 }}>[KWhEP/an]</span></td>
						<td className="font-medium align-left">Energie</td>
						<td className="font-medium align-left">Consommation <span style={{ fontSize: 8 }}>[KWhEP/an]</span></td>
					</tr>
					<tr>
						<th className="font-medium align-left" rowSpan="3" colSpan="1" scope="rowgroup">Chauffage</th>
						{[
							'_situation_initiale',
							'_scenario_1',
							'_scenario_2',
							'_scenario_3'
						].map((lower, index) => <Fragment key={index}>
							<th className="bg-cell align-left font-medium" scope="row"><JsonSyntheseSelect name={"chauffage_principal_nom" + lower} value={jsonSynthese["chauffage_principal_nom" + lower] || ""} options={energyTypes} onUpdate={updateJsonSynthese} /></th>
							<td className="bg-cell align-center"><JsonSyntheseInput name={"chauffage_principal_consomation" + lower} value={jsonSynthese["chauffage_principal_consomation" + lower]} onUpdate={updateJsonSynthese} /> </td>
						</Fragment>)}
					</tr>
					<tr>
						{[
							'_situation_initiale',
							'_scenario_1',
							'_scenario_2',
							'_scenario_3'
						].map((lower, index) => <Fragment key={index}>
							<th className="bg-cell align-left font-medium" scope="row"><JsonSyntheseSelect name={"chauffage_apoint_nom" + lower} value={jsonSynthese["chauffage_apoint_nom" + lower] || ""} options={energyTypes} onUpdate={updateJsonSynthese} /></th>
							<td className="bg-cell align-center"><JsonSyntheseInput name={"chauffage_apoint_consomation" + lower} value={jsonSynthese["chauffage_apoint_consomation" + lower]} onUpdate={updateJsonSynthese} /> </td>
						</Fragment>)}
					</tr>
					<tr>
						{[
							'_situation_initiale',
							'_scenario_1',
							'_scenario_2',
							'_scenario_3'
						].map((lower, index) => <Fragment key={index}>
							<th className="bg-cell align-left font-medium" scope="row"><JsonSyntheseSelect name={"chauffage_third_nom" + lower} value={jsonSynthese["chauffage_third_nom" + lower] || ""} options={energyTypes} onUpdate={updateJsonSynthese} /></th>
							<td className="bg-cell align-center"><JsonSyntheseInput name={"chauffage_third_consomation" + lower} value={jsonSynthese["chauffage_third_consomation" + lower]} onUpdate={updateJsonSynthese} /> </td>
						</Fragment>)}
					</tr>
					<tr>
						<td className="font-medium align-left">Refroidissement</td>
						<td colSpan="2"><JsonSyntheseInput name={"refroidissement_situation_initiale"} value={jsonSynthese["refroidissement_situation_initiale"]} onUpdate={updateJsonSynthese} /></td>
						<td colSpan="2"><JsonSyntheseInput name={"refroidissement_scenario_1"} value={jsonSynthese["refroidissement_scenario_1"]} onUpdate={updateJsonSynthese} /></td>
						<td colSpan="2"><JsonSyntheseInput name={"refroidissement_scenario_2"} value={jsonSynthese["refroidissement_scenario_2"]} onUpdate={updateJsonSynthese} /></td>
						<td colSpan="2"><JsonSyntheseInput name={"refroidissement_scenario_3"} value={jsonSynthese["refroidissement_scenario_3"]} onUpdate={updateJsonSynthese} /></td>
					</tr>
					<tr>
						<td className="font-medium align-left">ECS</td>
						{[
							'_situation_initiale',
							'_scenario_1',
							'_scenario_2',
							'_scenario_3'
						].map((lower, key) =>
							<Fragment key={key}>
								<th className="bg-cell align-left font-medium" scope="row">
									<JsonSyntheseSelect name={key === 0 ? "situationInitialeEnergieECS" : ("ecs_name" + lower)}
										value={jsonSynthese[key === 0 ? "situationInitialeEnergieECS" : ("ecs_name" + lower)] || ""}
										options={energyTypes}
										onUpdate={updateJsonSynthese}
									/></th>
								<td className="bg-cell align-center"><JsonSyntheseInput name={"ecs_cep" + lower} value={jsonSynthese["ecs_cep" + lower]} onUpdate={updateJsonSynthese} /> </td>
							</Fragment>)
						}
					</tr>
					<tr>
						<td className="font-medium align-left">Total</td>
						<td className="bg-cell align-center" colSpan="2">{numberToString(jsonSynthese['chauffage_principal_consomation_situation_initiale'] + jsonSynthese['chauffage_apoint_consomation_situation_initiale'] + jsonSynthese['ecs_cep_situation_initiale'] + jsonSynthese['refroidissement_situation_initiale'], 0) || ' '}</td>
						<td className="bg-cell align-center" colSpan="2">{numberToString(jsonSynthese['chauffage_principal_consomation_scenario_1'] + jsonSynthese['chauffage_apoint_consomation_scenario_1'] + jsonSynthese['ecs_cep_scenario_1'] + jsonSynthese['refroidissement_scenario_1'], 0) || ' '}</td>
						<td className="bg-cell align-center" colSpan="2">{numberToString(jsonSynthese['chauffage_principal_consomation_scenario_2'] + jsonSynthese['chauffage_apoint_consomation_scenario_2'] + jsonSynthese['ecs_cep_scenario_2'] + jsonSynthese['refroidissement_scenario_2'], 0) || ' '}</td>
						<td className="bg-cell align-center" colSpan="2">{numberToString(jsonSynthese['chauffage_principal_consomation_scenario_3'] + jsonSynthese['chauffage_apoint_consomation_scenario_3'] + jsonSynthese['ecs_cep_scenario_3'] + jsonSynthese['refroidissement_scenario_3'], 0) || ' '}</td>
					</tr>
				</tbody>
			</table>
			<br />
		</div>
	</div >
	);
};

export default TablePage2_1;