import MyAutocomplete from 'components/MyAutocomplete';
import toast from 'components/toast';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './UserPage.module.scss'

const UpdatableFieldFromList = ({
    list,
    name,
    value = null,
    title,
    onUpdate = () => { },
    labelProp = 'name',
    noNavigate = false
}) => {
    const navigate = useNavigate()
    const options = useMemo(() => list.map(l => ({ ...l, label: l[labelProp] })), [list, labelProp])

    const valueUpdated = (newValue) => {
        name !== 'status' && toast.success("N'oubliez pas d'ajouter un prix pour "+title+"!")
        return onUpdate({ [name]: newValue?.id })
    }

    const navigateToUserPage = useCallback(() =>
        navigate(`/user/${value}`), [navigate, value])

    return (
        <div>
            <h3
                className={!noNavigate && value ? classes.updatedListTitle: ''}
                onClick={!noNavigate && value ? navigateToUserPage: () => {}}
            >
                {title}
            </h3>
            <MyAutocomplete
                options={options}
                label={title}
                onUpdate={valueUpdated}
                defaultValue={value
                    ? options.find(({ id }) => id === value)
                    : value}
            />
        </div>
    );
};

export default UpdatableFieldFromList;