import App from "./App"
import ReactDOM from "react-dom"
import React from "react"
import 'intersection-observer'
import reportWebVitals from './reportWebVitals'
import 'styles/global.scss'
import './style.css'
import 'react-toastify/dist/ReactToastify.css'

ReactDOM.render(
  <App />,
  document.getElementById("root")
);

reportWebVitals()
