import Button from 'components/Button/Button';
import React from 'react';

const AirEauValue = ({
	setAirEauValue = () => { }
}) => {
	return (
		<div>
			<Button onClick={() => setAirEauValue('(7/55°C)')}>7/55°C</Button>
			<Button type='success' onClick={() => setAirEauValue('Auto-Adaptative (7/35°C)')}>Auto-Adaptative</Button>
		</div>
	);
};

export default AirEauValue;