import { Tooltip, Button, Typography } from '@material-ui/core'
import moment from 'moment'
import classes from './FileButton.module.scss'
import { InsertDriveFile as FileIcon } from '@material-ui/icons'
import { apiUrl, messageFileTypes } from 'helpers'

const ButtonIconByType = ({ type, file, src = false, size }) => {
	switch (type) {
		case messageFileTypes.IMAGE:
			return <img
				src={src || `${apiUrl}/file/visualisation/${file.id}/${localStorage.getItem('token')}`}
				alt={""}
				className={size ? classes[size] : classes.img} />
		case messageFileTypes.AUDIO:
			return <audio src={`${apiUrl}/stream/voice/${file.id}?token=${localStorage.getItem('token')}`} controls />
		case messageFileTypes.FILE:
			return <FileIcon className={classes.icon} />
		case messageFileTypes.DOCX:
			return <FileIcon className={classes.icon} />// redirection vers le docx page
		case messageFileTypes.PDF:
			return <FileIcon className={classes.icon} />//faire le viewer
		default:
			return <FileIcon className={classes.icon} />
	}
}

const FileButton = ({
	file,
	type,
	handleClick = () => { },
	noName = false,
	src = false,
	size,
	styleContainer = {},
}) => {
	if (type === messageFileTypes.AUDIO)
		return <audio src={`${apiUrl}/stream/voice/${file.id}?token=${localStorage.getItem('token')}`} controls />
	return (
		<div style={styleContainer || {}}>
			<Tooltip
				title={
					<div className={classes.tooltipTitle}>
						{`${file.name}${(file.updated_at || file.created_at) ? `\n\n${moment(file.updated_at || file.created_at).fromNow()}` : ''}`}
					</div>
				}
			>

				<Button onClick={handleClick(file)} classes={{ root: classes.button }}>
					<ButtonIconByType type={type} file={file} src={src} size={size} />
				</Button>
			</Tooltip>
			{!noName && <Typography classes={{ root: classes.label }}>{file.name}</Typography>}
		</div>
	)
}

export default FileButton