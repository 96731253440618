import { parseFloatFromString } from 'helpers';
import { isString } from 'lodash';
import React, { useMemo } from 'react';

const JsonSyntheseInput = ({ type = 'number', onUpdate = () => { }, name, value: valueReceived }) => {
	const value = useMemo(() => {
		if (!valueReceived) { return '' }
		if (isString(valueReceived)) {
			return parseFloatFromString(valueReceived.replace(/\s/gi, ''))
		}
		return valueReceived
	}, [valueReceived])
	return (
		<input
			onWheel={e => e.target.blur()}
			style={{ width: 100 }}
			type={type}
			onChange={({ target: { value } }) => onUpdate({ [name]: value })}
			value={value}
		/>
	);
};

export default JsonSyntheseInput;