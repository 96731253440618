import Button from 'components/Button';
import MyTable from 'components/MyTable/MyTable';
import { columnTypes } from 'components/MyTable/MyTable.constant';
import toast from 'components/toast';
import { formatDate } from 'dates';
import React, { useCallback, useMemo } from 'react';
import classes from 'styles/classes.module.scss'
import { useApi } from 'hooks/useApi';

const DepositTable = ({
    deposits = [],
    setDeposits = () => { }
}) => {
	const { deleteDeposit } = useApi()
    const removeDeposit = useCallback(async (depositId) => {
        try {
            await deleteDeposit(depositId)
            setDeposits(deposits => deposits.filter(({ id }) => id !== depositId))
            toast.success('Acompte supprimé.')
        } catch (error) {
            console.log('error', error)
            toast.success('Erreur durant la suppression de l\'acompte: ' + error.message)
        }
    }, [deleteDeposit, setDeposits])

    const columns = useMemo(() => [
        { title: 'Montant', field: 'deposit' },
        { title: 'Date de transfert', field: 'date', type: columnTypes.DATE, render: ({ date }) => formatDate(new Date(date)) },
        {
            title: 'Actions', render: ({ id }) => <div>
                <Button type='danger' onClick={(e) => {
                    e.stopPropagation()
                    removeDeposit(id)
                }}>Supprimer</Button>
            </div>
        },
    ], [removeDeposit])

    return (
        <div style={{ maxWidth: "100%" }} className={classes.marginAuto}>
            <MyTable
                columns={columns}
                data={deposits}
                title={'Acomptes'}
                options={{
                    paging: true,
                    pageSize: 50
                }}
            />
        </div>
    );
};

export default DepositTable;