import { useRef, useState } from "react"
import classes from './FileInput.module.scss'
import { Button } from "semantic-ui-react"
import FileButton from "./FileButton"
import Compressor from 'compressorjs';
import { getFileExtension, getFileTypeFromExtension } from "helpers"

const FileInput = ({
	onChange,
}) => {
	const sizeMaxFile = 3000000000
	const [loading, setLoading] = useState(false)
	const lang = {
		required: 'Ce champ est requis',
		emptyFields: 'Champs manquants',
		submit: 'Valider',
		file: 'Choisissez un fichier',
		fileError: 'Taille du fichier trop large, veuillez en choisir un autre.',
		fileUpdate: 'Modifier le fichier',
		tooBig: 'Fichier trop volumineux.',
		uploadFile: 'Télécharger',
		error: 'Erreur',
		success: 'Succès',
		nothingUpdated: 'Rien n\'a été actualisé dans le formulaire.',
		invalidEmail: 'Email invalide',
		search: 'Rechercher',
	  }
	const [file, setFile] = useState()
	const fileRef = useRef()
	const [sizeTooLarge, setSizeTooLarge] = useState(false)
	const [fileSize, setFileSize] = useState(0)
	const handleFileChange = ({ target: { files } }) => {
		setLoading(true)
		const file = files[0]
		if (!file) { return setLoading(false) }
		if (file.size <= sizeMaxFile) {
			setSizeTooLarge(false)
			setLoading(false)
			setFile({
				src: window.URL.createObjectURL(new Blob(files)),
				type: getFileTypeFromExtension(getFileExtension(file.name)),
				name: file.name,
			})
			return onChange({ target: { value: file } })
		}

		if (file.type.includes('image')) {
			new Compressor(file, {
				quality: 0.6,
				// The compression process is asynchronous,
				// which means you have to access the `result` in the `success` hook function.
				success(blob) {
					const compressedFile = new File([blob], file.name, { type: file.type })
					if (compressedFile.size > sizeMaxFile) { // >25MO
						setFileSize(file.size)
						setLoading(false)
						return setSizeTooLarge(true)

					}
					setSizeTooLarge(false)
					setFile({
						src: window.URL.createObjectURL(new Blob(files)),
						type: getFileTypeFromExtension(getFileExtension(file.name)),
						name: file.name,
					})
					setLoading(false)
					return onChange({ target: { value: compressedFile } })
				},
				error(err) {
					setLoading(false)
					console.log(err.message);
				},
			})
		} else {
			setLoading(false)
			setFileSize(file.size)
			return setSizeTooLarge(true)
		}
	}

	const handleClick = () => {
		fileRef.current.click()
	}

	return <div>
		{file && <FileButton file={file} type={file.type} src={file.src} size="imgLittle" />}
		<input
			type="file"
			id="upload-file-messenger"
			style={{ display: 'none' }}
			accept="text/csv,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,image/png,image/jpeg,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,image/*,application/zip"
			onChange={handleFileChange}
			ref={fileRef}
		/>
		<Button
			classes={{ root: classes.iconButton }}
			onClick={handleClick}
			loading={loading}
		>
			{
				sizeTooLarge ? lang.fileError + `(${Math.round(fileSize / 1000000)}MB)` :
					(file || {}).filename || lang.uploadFile + ' (< 25MB)'
			}
		</Button>
	</div>
}

export default FileInput
